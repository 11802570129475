import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import InputError from '../InputError';
import {getParams} from 'helpers/get';
import useDebounce from 'hooks/useDebounce';
import {addParamsRoute} from 'helpers/url';
import {getFieldError} from 'helpers/form';
import Label from '../Label';

// Styles
const Wrap = styled('div')`
  width: 100%;
  display: flex;
  flex-flow: column-reverse nowrap;
  &:not(:last-child){
    margin-bottom: 16px;
  }
`
const InputWrap = styled('div')`
  position: relative;
  display: flex;
  width: ${({ fullWidth }) => fullWidth && '100%'};
  flex-flow: column-reverse nowrap;
`
const Input = styled('input')`
  height: 40px;
  outline: none;
  padding: 15px 12px 15px ${({ prefix }) => prefix ? '50px' : '12px'} !important;
  padding: 8px ${({ suffix }) => suffix ? '45px' : '10px'} 8px ${({ prefix }) => prefix ? '35px' : '10px'};
  color: ${({ theme }) => theme.palette.black};
  background: #F0F2F4;
  border: ${({ theme }) => theme.input.primary.border};
  border-radius: ${({ theme }) => theme.input.primary.borderRadius};
  transition: ${({ theme }) => `${theme.transition.transition}`};
  &::placeholder{
    color: ${({ theme }) => theme.color.placeholder};
  }
  &:focus{
    background: ${({ theme }) => theme.input.primary.background};
    border-color: ${({ theme }) => theme.palette.blue};
    transition: ${({ theme }) => `${theme.transition.transition}`};
  }
`

const StyledTextareaField = styled('textarea')`
  padding: 8px ${({ suffix }) => suffix ? '45px' : '10px'} 8px ${({ prefix }) => prefix ? '35px' : '10px'};
  height: ${({ size }) => size === 'small' ? '36px' : '50px'};
  color: ${({ theme }) => theme.color.field};
  background: white;
  border: ${({ theme }) => theme.input.primary.border};
  border-radius: ${({ theme }) => theme.input.primary.borderRadius};
  transition: ${({ theme }) => `${theme.transition.transition}`};
  &::placeholder{
    color: ${({ theme }) => theme.color.placeholder};
  }
  &:focus{
    background: ${({ theme }) => theme.input.primary.background};
    border-color: ${({ theme }) => theme.palette.blue};
    transition: ${({ theme }) => `${theme.transition.transition}`};
  }
`

const Prefix = styled('div')`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    color: ${({ theme, focused }) => focused ? theme.palette.primary : '#b1baca'};
    font-size: 24px;
    transition: ${({ theme }) => `all ${theme.transition.medium}`};
  }
`

// Component
const TextField = props => {
  const {
    type = 'text',
    input,
    customChange,
    meta,
    error,
    label,
    searchKey = 'search',
    searchable,
    withoutLabel,
    prefix,
    fullWidth,
    multiline,
    onChange,
    defaultValue,
    setFocused,
    focused,
    ...rest
  } = props
  const history = useHistory()
  // useState
  // Handlers
  const handleFocus = () => setFocused && setFocused(true)
  const handleBlur = () => setFocused && setFocused(false)
  const searchParams = getParams(history, [searchKey])
  const search = searchParams?.[searchKey] || ''
  const [searchTerm, setSearchTerm] = useState(search)
  const debounceSearchTerm = useDebounce(searchTerm, 1000)
  useEffect(() => {
    if (searchable) {
      addParamsRoute({ [searchKey]: debounceSearchTerm }, history)
    }
  }, [debounceSearchTerm])

  const typeInput = searchable ? (
    <Input
      defaultValue={search}
      type={type}
      prefix={prefix}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...input}
      {...rest}
      onChange={(value) => {
        setSearchTerm(value.target.value)
        if (customChange) {
          customChange({ event: value, input })
        } else {
          input && input.onChange(value)
          onChange && onChange(value)
        }
      }}
    />
  ) : (
    <Input
      defaultValue={defaultValue}
      type={type}
      prefix={prefix}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...input}
      {...rest}
      onChange={(value) => {
        if (customChange) {
          customChange({ event: value, input })
        } else {
          input && input.onChange(value)
          onChange && onChange(value)
        }
      }}
    />
  )

  // Textarea
  const typeTextarea = (
    <StyledTextareaField
      prefix={prefix}
      style={{ width: '100%', lineHeight: '20px', height: '70px' }}
      step={'any'}
      type={type}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...input}
      {...rest}
    />
  )
  // Render
  return (
    <Wrap>
      <InputError>{error || getFieldError(meta)}</InputError>
      <InputWrap fullWidth={fullWidth}>
        <Prefix
          focused={focused}
        >
          {prefix && prefix}
        </Prefix>
        {multiline
          ? typeTextarea
          : typeInput}
      </InputWrap>
      {!withoutLabel && label && (
        <Label
          focused={focused}
        >
          {label}
        </Label>
      )}
    </Wrap>
  )
}

TextField.propTypes = {
  type: PropTypes.string,
  prefix: PropTypes.any,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.any,
  searchable: PropTypes.bool,
  searchKey: PropTypes.string
}

export default TextField
