import React from 'react';
import ProfileGrid from './Grid';
import toCamelCase from 'helpers/toCamelCase';

const ProfileContainer = () => {
  const user = toCamelCase(window.Telegram.WebApp.initDataUnsafe)
  return (
    <ProfileGrid
      user={user}
    />
  );
};

export default ProfileContainer;
