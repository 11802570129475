import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import * as ROUTES from 'constants/routes'

// Styles
const Wrap = styled('div')`
  a{
    color: ${({ theme }) => theme.palette.primary};
    font-weight: 500;
    letter-spacing: .3px;
  }
`

// Component
const NotFoundGrid = () => {
  return (
    <Wrap>
      Страница не найдена, перейдите на <Link to={ROUTES.ROOT_PATH}>главную страницу</Link>
    </Wrap>
  )
}

export default NotFoundGrid
