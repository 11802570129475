import {map, path} from "ramda";

export const orderQuickEditSerializer = (values) => {
  const {products, isConfirmed} = values
  const productsMap = map((item) => {
    return {
      id: path(['id'], item),
      amount: Number(path(['amount'], item) || path(['amount'], item) === 0) ? Number(path(['amount'], item)) : null,
      product: path(['productId'], item)
    }
  }, products || []).filter(item => item.amount > 0)

  return {
    products: productsMap,
    is_confirmed: isConfirmed,
  }
}
