import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  //border-bottom: 1px solid rgb(228, 228, 230);
  margin-bottom: ${({withOutMargin}) => withOutMargin ? '0' : '15px'};
  ${({styles}) => styles}
`

const Parent = styled('p')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  ${({styles}) => styles}
`

const Child = styled('p')`
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 16px;
  text-align: right;
  color: #333333;
  ${({styles}) => styles}
`


const Description = (props) => {
  const {child, parent, childStyles, parentStyles, styles, withOutMargin} = props
  return (
    <Wrapper withOutMargin={withOutMargin} styles={styles}>
      <Parent styles={parentStyles}>
        {parent}
      </Parent>
      <Child styles={childStyles}>
        {child}
      </Child>
    </Wrapper>
  )
}

Description.propTypes = {
  parent: PropTypes.any.isRequired,
  parentStyles: PropTypes.object,
  styles: PropTypes.object,
  line: PropTypes.bool,
  childStyles: PropTypes.object,
  child: PropTypes.any.isRequired
}

export default Description
