const ID = ':id(\\d+)'

// Root path
export const ROOT_PATH = '/'

export const BASKET_PATH = '/basket/'
export const PROFILE_PATH = '/profile/'
export const HISTORY_PATH = '/history/'
export const HISTORY_ITEM_PATH = '/history/%d/'
export const HISTORY_ITEM_URL = `/history/${ID}/`
export const EDIT_ORDER_URL = `/edit-order/${ID}/`
export const EDIT_ORDER_PATH = `/edit-order/%d/`
