import React from 'react'
import PropTypes from 'prop-types'
import {isEmpty} from 'ramda'
import mapIndexed from 'helpers/mapIndexed'
import Loader from 'components/Loader'
import NoResults from 'components/NoData/NoResults'
import {useTranslate} from 'helpers/translate';

const Mapper = (props) => {
  const {data,  itemProps, loading, content, listElement, itemElement, itemElementProps} = props
  const {t} = useTranslate()
  const mappedData = data && mapIndexed((item, index) => {
    if (itemElement) {
      return (
        <props.itemElement {...itemElementProps} key={index}>
          <props.item data={item} {...itemProps} key={index} />
        </props.itemElement>
      )
    }
    return (
      <props.item data={item} {...itemProps} key={index} />
    )
  }, data)
  return (
    <>
      {loading && <Loader />}
      {!loading && !isEmpty(data) &&
      <>
        {content}
        {listElement ? <props.listElement>{mappedData}</props.listElement> : mappedData}
      </>}
      {isEmpty(data) && <NoResults text={t('product_not_found')} />}
    </>
  )
}

Mapper.propTypes = {
  data:PropTypes.array,
  item:PropTypes.elementType,
  content:PropTypes.elementType,
  listElement:PropTypes.elementType,
  itemElement:PropTypes.elementType,
  itemElementProps:PropTypes.object,
  itemProps:PropTypes.object,
  loading:PropTypes.bool
}

export default Mapper
