import styled from 'styled-components'

// Styles
const Label = styled('label')`
  color: ${({ theme, focused }) => focused ? theme.palette.primary : theme.color.label};
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 6px;
  transition: ${({ theme }) => theme.transition.primary};
`

export default Label
