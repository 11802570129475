import React from 'react';
import YandexMapField from "../../../Fields/Map/YandexMapField";
import useFetch from "../../../../hooks/useFetch";
import {addressListFetchAction} from "../../../../actions/adress";
import * as STATES from "../../../../redux/reducers/states";
import {path} from "ramda";

const Adress = ({form, values, setOpenAddress}) => {
  const addressList = useFetch({
    action: addressListFetchAction,
    stateName: STATES.GET_ADDRESS,
    onSuccess: ({value}) => {
      form.change('userAddress', (path(['0', 'id'], value) || '').toString())
    },
    childIsNull: true
  })
  return (
      <YandexMapField setOpenAddress={setOpenAddress} addressList={addressList}/>
  );
};

export default Adress;
