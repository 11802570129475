import React from "react";
import {Field} from "react-final-form";
import TextField from "../../../Fields/Text";
import styled from "styled-components";
import {phoneNumberParse, validatePhoneNumber, withoutSpaceParse} from "../../../../helpers/fieldParsers";

const NumberBox = styled('div')`
    display: flex;
    align-items: center;
    position: relative;
    
    input {
        padding: 25px 17px 25px 50px !important;
        font-size: 16px !important;
        font-weight: 500 !important;
    }
`

const CodeBox = styled('div')`
    position: absolute;
    left: 10px;
    top: 38px;
    z-index: 11;
    font-size: 16px;
`

const PhoneNumberField = ({name}) => {
    return (
        <NumberBox>
            <CodeBox>
                +998
            </CodeBox>
            <Field
                name={name}
                placeholder={'00 000 00 00'}
                label={'Номер телефона'}
                component={TextField}
                type={'tel'}
                format={phoneNumberParse}
                parse={withoutSpaceParse}
            />
        </NumberBox>
    )
}

export default PhoneNumberField
