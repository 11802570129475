import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {join} from "ramda";
import InputError from "../../../Fields/InputError";
import {getFieldError} from "../../../../helpers/form";

const VerificationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const InputBox = styled.input`
  width: 50px;
  height: 60px;
  font-size: 18px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: orange;
  }
`;

const VerificationCodeField = ({input, meta, error}) => {
    const [code, setCode] = useState(new Array(6).fill(''));
    const inputsRef = useRef([]);

    const handleChange = (value, index) => {
        if (/^\d$/.test(value) || value === '') {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            // Focus the next input field if not the last input
            if (value !== '' && index < 5) {
                inputsRef.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && code[index] === '') {
            if (index > 0) {
                inputsRef.current[index - 1].focus();
            }
        }
    };
    useEffect(() => {
        input.onChange(join('', code))
    }, [code]);
    return (
        <div>
            <VerificationContainer>
                {code.map((digit, index) => (
                    <InputBox
                        key={index}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (inputsRef.current[index] = el)}
                    />
                ))}
            </VerificationContainer>
            <InputError>{error || getFieldError(meta)}</InputError>
        </div>

    )
}



export default VerificationCodeField
