import Layout from 'components/Layout';
import * as ROUTES from 'constants/routes';
import MainContainer from 'components/Pages/Main';
import NotFoundContainer from 'components/Pages/NotFound';
import BasketContainer from 'components/Pages/Basket';
import Profile from 'components/Pages/Profile';
import History from 'components/Pages/History';
import HistoryDetail from 'components/Pages/History/Detail';
import EditOrder from 'components/Pages/EditOrder';

const routes = [
  // Sign in
  {
    path: ROUTES.BASKET_PATH,
    layout: Layout,
    component: BasketContainer
  },
  {
    path: ROUTES.PROFILE_PATH,
    layout: Layout,
    component: Profile
  },
  {
    path: ROUTES.HISTORY_PATH,
    layout: Layout,
    component: History,
    routes: [
      {
        path: ROUTES.HISTORY_ITEM_URL,
        layout: Layout,
        component: HistoryDetail
      }
    ]
  },
  {
    path: ROUTES.EDIT_ORDER_URL,
    layout: Layout,
    component: EditOrder
  },
  {
    path: ROUTES.ROOT_PATH,
    layout: Layout,
    component: MainContainer
  },
  {
    path: '*',
    layout: Layout,
    component: NotFoundContainer
  }
]

export default routes
