import {useHistory, useLocation, useParams} from 'react-router-dom';
import {parseParams} from 'helpers/url';
import {path} from 'ramda';

const useHistoryObj = () => {
  const history = useHistory()
  const location = useLocation()
  const params = useParams()

  const search = path(['search'], location)
  const pathname = path(['pathname'], location)

  const searchObj = parseParams(search)

  const getParam = (string) => path([string], {...searchObj, ...params})
  const getParams = () => {
    return {
      ...searchObj,
      ...params
    }
  }
  return {
    ...history,
    search,
    pathname,
    searchObj,
    getParam,
    getParams
  }
}

export default useHistoryObj
