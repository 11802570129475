import React from 'react';
import styled from 'styled-components';

const TagContainer = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: #f1f3f5;
  border-radius: 16px;
  padding: 6px 12px;
  user-select: none;
  font-size: 14px;
  color: #333;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const Text = styled.span`
  margin-right: 8px;
`;



const Tag = ({ text, icon, onClick }) => (
    <TagContainer onClick={onClick}>
      <Text>{text}</Text>
      {icon}
    </TagContainer>
);

export default Tag;
