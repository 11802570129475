import React from 'react'
import styled from 'styled-components'
import {prop} from 'ramda'
import PropTypes from 'prop-types'

const Wrap = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: ${({background}) => background || '#f1f1f2'};
  min-height: 50px;
  border-radius: 10px;
  ${({styles}) => styles};
`
const Checkbox = styled('div')`
  position: relative;
  display: inline-block;
  padding: 5px 0 3px;
  margin-left: auto;
  margin-right: 12px;
  label{
    width: 51px;
    height: 31px;
    background: ${({disabled}) => disabled ? '#e8e8e8' : '#ccc'};
    position: relative;
    display: inline-block;
    border-radius: 46px;
    transition: 0.2s;
    &:after{
      content: '';
      position: absolute;
      border-radius: 100%;
      left: 2px;
      z-index: 2;
      background: ${({disabled}) => disabled ? '#ffffff61' : '#fff'};
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      transition: 0.2s;
      top: 2px;
      width: 27px;
      height: 27px;
    }
  }
  input{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
    &:checked + label{
      background: ${({theme, disabled}) => disabled ? '#e8e8e8' : theme.palette.blue};
      &:after{
        left: 22px;
        background: ${({theme, disabled}) => disabled ? '#ffffff61' : theme.palette.white};
      }
    }
  }
`
const Label = styled('span')`
  padding: ${({padding}) => padding || '8px 8px 8px 17px'};
  line-height: 24px;
  color: ${({theme, disabled}) => disabled ? '#ccc' : ''}
`
const StyledCheckbox = styled('input')``

const Switcher = props => {
  const {
    label,
    input,
    onChange,
    labelPadding,
    disabled,
    styles,
    background,
    ...defaultProps
  } = props

  return (
    <Wrap
      styles={styles}
      background={background}
    >
      <Label disabled={disabled} padding={labelPadding}>{label}</Label>
      <Checkbox
        disabled={disabled}
      >
        <StyledCheckbox
          {...defaultProps}
          {...input}
          type={'checkbox'}
          onChange={!disabled ? (onChange || prop('onChange', input)) : undefined}
          readOnly={!!disabled}
          disabled={disabled}
        />
        <label />
      </Checkbox>
    </Wrap>
  )
}

Switcher.propTypes = {
  label:PropTypes.any,
  onChange:PropTypes.func,
  disabled:PropTypes.bool
}

export default Switcher
