import MainGrid from './Grid';
import useFetch from 'hooks/useFetch';
import {productsListFetchAction} from 'actions/products';
import * as STATES from 'redux/reducers/states'
import {isEmpty, path, prop} from 'ramda';
import {useState} from 'react';
import storage from 'helpers/storage';
import {useLocation} from 'react-router-dom';
import {parseParams} from 'helpers/url';
import {useDispatch} from 'react-redux';
import useCompareEffect from 'hooks/useCompareEffect';
import * as ACTION_TYPES from 'redux/types';

const MainContainer = () => {
  const [brands, setBrands] = useState(storage('brands').get() || [])
  const [page, setPage] = useState(1)
  const [submit, setSubmit] = useState(false)
  const [price, setPrice] = useState(storage('price').get() || {form: null, to: null})
  const [productTypes, setProductTypes] = useState(storage('productTypes').get() || [])
  const dispatch = useDispatch()

  const products = useFetch({
    'action': (params) => productsListFetchAction(brands, price, page, params, page !== 1),
    'stateName': STATES.PRODUCTS,
    'child': 'list',
    'dependencies': [submit, page]
  })

  const location = useLocation()
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const searchParam = prop('search', searchObj)
  useCompareEffect(() => {
    setPage(1)
    dispatch({
      type: ACTION_TYPES.MAIN_PRODUCTS_LIST_FETCH_ACTION, payload: []
    })
  }, [searchParam])

  const hasMore = path(['data', 'next'], products)
  const list = path(['data', 'results'], products)
  const loading = page !== 1 ? false : path(['loading'], products) && !isEmpty(list)
  const moreLoading = page === 1 ? false : path(['loading'], products)
  return (
    <MainGrid
      moreLoading={moreLoading}
      loading={loading}
      price={price}
      hasMore={hasMore}
      setPrice={setPrice}
      page={page}
      productTypes={productTypes}
      setPage={setPage}
      setProductTypes={setProductTypes}
      setBrands={setBrands}
      setSubmit={setSubmit}
      brands={brands}
      list={list}
    />
  )
}

export default MainContainer
