import {
  assoc,
  compose,
  curry,
  __,
  filter,
  fromPairs,
  head,
  isEmpty,
  join,
  map,
  mergeRight,
  not, path,
  pipe,
  prop,
  split,
  toPairs,
  defaultTo
} from 'ramda'

const getPathnameFromUrl = pipe(
  split('?'),
  head
)

export const redirect = curry(({ pathname, params }, history) =>
  compose(
    history.push,
    appendParamsToUrl(params),
    defaultTo(path(['location', 'pathname'], history))
  )(pathname)
)

export const parseParams = url => {
  const [, search] = split('?', url)
  const searchToObject = pipe(
    split('&'),
    map(split('=')),
    fromPairs
  )
  return search ? searchToObject(search) : {}
}

export const paramsToSearch = pipe(
  toPairs,
  map(join('=')),
  join('&')
)

export const appendParamsToUrl = curry((appendParams, url) => {
  const pathname = getPathnameFromUrl(url)
  const params = parseParams(url)
  const newParams = pipe(
    mergeRight(params),
    filter(
      pipe(
        isEmpty,
        not
      )
    )
  )(appendParams)
  return pathname + '?' + paramsToSearch(newParams)
})

export const appendParamToRoute = (history, value, query) => {
  const search = path(['location', 'search'], history)

  if (value) {
    history.push({
      search: appendParamsToUrl({ [query]: decodeURI(value) }, search)
    })
  } else {
    history.push({
      search: appendParamsToUrl({ [query]: '' }, search)
    })
  }
}

export const getSearchParam = (paramName, history) => {
  return prop(paramName, parseParams(history.location.search))
}

export const addParamsRoute = curry((params, history) =>
  compose(
    redirect(__, history),
    assoc('pathname', __, {}),
    appendParamsToUrl(params),
    path(['location', 'search'])
  )(history)
)
