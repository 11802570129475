
export const theme = {
  palette:{
    primary:'#2c3a50',
    black:'#000',
    white:'#fff',
    blue:'#6770e6',
    red:'#e66767',
    green:'#38d06c',
    orange:'#fbb432',
    grey:'#8f8e94',
    lightGrey:'#c1c1c1',
    greyOpacity:'rgba(140, 140, 140, 0.1)'
  },
  background:{
    primary:'#f3f3f3',
    secondary:'#fff',
    mask:'rgba(0, 0, 0, 0.4)'
  },
  text:{
    primary:'#6c7079',
    secondary:'#8c8c8c'
  },
  color: {
    primary: '#2c3a50',
    secondary: '#69768d',
    table: '#2e3640',
    field: '#2c3a50',
    placeholder: '#9ea4ae',
    label: '#69768d',
    tableHead: '#69768d'
  },
  button:{
    primary:{
      background:'#6770e6',
      color:'#fff'
    },
    secondary:{
      background:'#dedede',
      color:'#000'
    },
    disabled:{
      background:'#dedede',
      color:'#c1c1c1'
    }
  },
  nav:{
    primary:{
      background: 'white',
      backdropFilter: 'blur(20px)',
      borderBottom:'1px solid #EFEFEF'
    }
  },
  input:{
    primary:{
      background:'rgba(142, 142, 147, 0.12)',
      backgroundIndicator:'#808085',
      color:'#000',
      fontSize:'17px',
      lineHeight:'22px',
      letterSpacing:'-0.02em',
      padding:'7px 10px',
      border: '1px solid transparent',
      borderRadius:'44px',
      borderFocus:'1px solid #6770e6',
      width:'100%',
      '&::placeholder':{
        color:'#8e8e93'
      }
    },
    secondary:{
      background:'rgba(142, 142, 147, 0.12)',
      backgroundIndicator:'#808085',
      color:'#000',
      fontSize:'17px',
      lineHeight:'22px',
      letterSpacing:'-0.02em',
      padding:'7px 10px',
      border:'none',
      borderRadius:'10px',
      borderFocus:'1px solid #6770e6',
      width:'100%',
      '&::placeholder':{
        color:'#8e8e93'
      }
    },
    disabled:{
      color:'#a1a1a1'
    }
  },
  border:{
    primary:'1px solid #e4e4e6'
  },
  fontSize:{
    primary:'15px'
  },
  borderRadius:{
    primary:'10px',
    secondary:'5px'
  },
  boxShadow:{
    primary:'0 0 1px rgba(0, 0, 0, 0.3)'
  },
  transition:{
    primary:'all .2s ease-out'
  },
  skeleton:{
    primary:'#eee'
  }
}

export const palette = [
  '#24aadd',
  '#e5ab09',
  '#bad7c8',
  '#ac88bf',
  '#babbce',
  '#bad7c8',
  '#5189a0',
  '#e86741',
  '#98942e',
  '#f6b4da',
  '#fde482',
  '#9579d1',
  '#40817a',
  '#264b77'
]
