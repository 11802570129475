import commaNumber from 'comma-number'

const formatNumber = (amount, suffix, bindWith = ', ') => {
  const number = Number(amount)
  const format = commaNumber.bindWith(bindWith)
  return (
    <>
      {format(number)}
      {suffix && <span style={{ marginLeft: 3 }}>
              {suffix}
      </span>}
    </>
  )
}

export default formatNumber
