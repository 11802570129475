import React from 'react';
import BasketGrid from './Grid';
import {connect} from 'react-redux';
import useCard from 'hooks/useCard';
import {compose} from 'recompose';
import {prop} from 'ramda';

const enhance = compose(
  connect((state) => {
    const orderCreate = prop('orderCreate', state)
    return {
      orderCreate
    }
  })
)

const BasketContainer = (props) => {
  const {orderCreate} = props
  const {products, total} = useCard(null)

  const loading = prop('loading', orderCreate)
  return (
    <BasketGrid
      loading={loading}
      total={total}
      products={products}
    />
  );
};

export default enhance(BasketContainer);
