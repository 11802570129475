import styled from 'styled-components';
import Select from 'components/Fields/Select';
import Button from 'components/Navigation/Components/Button';
import storage from 'helpers/storage';
import useFetch from 'hooks/useFetch';
import * as STATES from 'redux/reducers/states';
import {brandsListFetchAction} from 'actions/brands';
import Loader from 'components/Loader';
import {path} from 'ramda';

const PRICE = 'price'

const Wrapper = styled('div')`
  width: 100%;
  position: relative;
  height: calc(100vh - 78px);
`

const Input = styled('input')`
  width: 100%;
  height: 46px;
  padding-left: 33px;
  padding-right: 12px;
  outline: none;

  &:focus {
    outline: none;
  }

  background: #F0F2F4;
  border: 1px solid #D6DBE0;
  border-radius: 5px;
`

const Fixed = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  height: max-content;
  width: 100%;
`

const Row = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`

const Label = styled('div')`
  position: relative;
  width: 49%;

  & p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 145%;
    letter-spacing: 0.005em;
    color: #69707E;
    mix-blend-mode: normal;
    position: absolute;
    left: 12px;
    top: 11px;
  }
`

const Title = styled('div')`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin-top: 20px;
  color: #282828;
`

const MainFilter = (props) => {
  const {brands, setBrands, price, setPrice, setFilterOpen, setSubmit} = props
  const brandsList = useFetch({
    'action': brandsListFetchAction,
    'stateName': STATES.BRANDS,
    'child': 'list'
  })

  const results = path(['data', 'results'], brandsList)
  const loading = path(['loading'], brandsList)

  if (loading) {
    return (
      <Loader />
    )
  }
  return (
    <Wrapper>
      <Select
        title={'Бренд'}
        data={results ? results : []}
        selects={brands}
        setSelects={setBrands}
        storageName={'brands'}
      />
      {/*<Select*/}
      {/*  styles={{*/}
      {/*    marginTop: '20px'*/}
      {/*  }}*/}
      {/*  title={'Тип продукта'}*/}
      {/*  data={data}*/}
      {/*  selects={productTypes}*/}
      {/*  setSelects={setProductTypes}*/}
      {/*  storageName={'productTypes'}*/}
      {/*/>*/}
      {/*<Title>*/}
      {/*  Цена*/}
      {/*</Title>*/}
      {/*<Row>*/}
      {/*  <Label>*/}
      {/*    <p>от</p>*/}
      {/*    <Input defaultValue={price.from} onChange={(event) => {*/}
      {/*      setPrice({...price, from: event.target.value})*/}
      {/*      storage(PRICE).set({...price, from: event.target.value})*/}
      {/*    }}/>*/}
      {/*  </Label>*/}
      {/*  <Label>*/}
      {/*    <p>до</p>*/}
      {/*    <Input defaultValue={price.to} onChange={(event) => {*/}
      {/*      setPrice({...price, to: event.target.value})*/}
      {/*      storage(PRICE).set({...price, to: event.target.value})*/}
      {/*    }}/>*/}
      {/*  </Label>*/}
      {/*</Row>*/}
      <Fixed>
        <Button
          onClick={() => {
            setFilterOpen(false)
            price.from || price.to || brands && setSubmit(Math.random())
          }}
          title={'Показать товаров'} height={'55px'}/>
      </Fixed>
    </Wrapper>
  )
}

export default MainFilter
