import * as ACTION_TYPES from 'redux/types'

export const openErrorAction = payload => {
  return {
    type:ACTION_TYPES.ERROR_OPEN,
    payload
  }
}

export const closeErrorAction = () => {
  return {
    type:ACTION_TYPES.ERROR_CLOSE
  }
}
