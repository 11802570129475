import React, {useEffect, useState} from 'react';
import TitleBar from 'components/Navigation/TitleBar';
import Container from 'components/Container';
import Mapper from 'components/Mapper';
import {Field, Form as FinalForm} from 'react-final-form'
import {useHistory} from 'react-router';
import {HISTORY_ITEM_PATH} from 'constants/routes';
import styled from 'styled-components';
import formatNumber from 'helpers/formatNumber';
import {ChevronRight} from 'react-feather';
import Description from 'components/Description';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import {useTranslate} from 'helpers/translate';
import ListCard2 from 'components/ListCard2';
import sprintf from 'sprintf';
import Switcher from "../../../Fields/Switcher";

const Total = styled('div')`
  margin-top: 10px;
  background: white;
  padding: 15px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 5%);
  border-radius: 10px;
`

const Fixed = styled('button')`
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%;
  user-select: none;
  cursor: pointer;
  height: 55px;
  outline: none;
  border: none;
  background: #4c956c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  color: white;
`
const FixedSwitcher = styled.div`
  position: fixed;
  bottom: 54px;
  left: 0;
  right: 0;
`

const Basket = styled('div')`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    margin-top: 3px;
    margin-left: 3px;
  }
`

const styles = {
  wrapper: {
    marginTop: '5px'
  },
  button: {
    marginTop: '15px'
  },
  parent: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    color: "#333333"
  },
  child: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    color: '#4c956c'
  }
}

const EditOrderGrid = (props) => {
  const {products, total, onSubmit, deleteProduct, loading, currency, onSetProduct, id} = props
  const [loading1, setLoading] = useState(false)
  useEffect(() => {
    if (loading1) {
      setTimeout(() => setLoading(false), 300)
    }
  }, [loading1])
  const history = useHistory()
  const {t} = useTranslate()
  return (
      <FinalForm
          onSubmit={onSubmit}
          render={({handleSubmit, ...formProps}) => {
            return (
                <form onSubmit={handleSubmit}>
                  <TitleBar
                      title={'Изменить Заказ'}
                      icon={'back'}
                      onClick={() => {
                        history.push({
                          pathname: sprintf(HISTORY_ITEM_PATH, id),
                          search: '?status=pending'
                        })
                      }}
                  />
                  <Container hasStatusBar hasFixedBar bottom={'118px'}>
                    <Total>
                      <Description
                          withOutMargin
                          parent={t('order_price')}
                          child={formatNumber(total, currency, ' ')}
                          parentStyles={styles.parent}
                          childStyles={styles.child}
                      />
                    </Total>
                    <Mapper
                        loading={loading1}
                        data={products}
                        itemProps={{
                          customCurrency: currency,
                          onChange: onSetProduct,
                          onDelete: (id) => {
                            deleteProduct(id)
                          }
                        }}
                        item={ListCard2}
                    />
                    <FixedSwitcher>
                      <Field
                          component={Switcher}
                          name={'isConfirmed'}
                          type={'checkbox'}
                          label={'Подтвердить заказ'}
                      />
                    </FixedSwitcher>
                    <Fixed type={'submit'} disabled={loading}>
                      {
                        loading ?
                            <Loader/>
                            :
                            <>
                              <p>{formatNumber(total, currency, ' ')}</p>
                              <Basket>
                                <p>Изменить заказ № {id}</p>
                                <ChevronRight size={20}/>
                              </Basket>
                            </>
                      }
                    </Fixed>
                  </Container>
                </form>
            )
          }}
      />
  )
}

EditOrderGrid.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  total: PropTypes.any.isRequired,
  products: PropTypes.any.isRequired
}

export default EditOrderGrid
