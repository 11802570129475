export const ORDER_CREATE = 'orderCreate'
export const LIST = 'list'
export const ITEM = 'item'
export const PRODUCTS = 'products'
export const BRANDS = 'brands'
export const HISTORY = 'history'
export const ERROR = 'error'
export const CHECK_AUTH = 'checkAuth'
export const SEND_CONFIRM = 'sendConfirm'
export const CONFIRM_PHONE = 'confirmPhone'
export const GET_ADDRESS = 'getAddress'
export const CREATE_ADDRESS = 'createAddress'
