import {
  curry,
  path,
  pathOr,
  compose,
  prop,
  filter,
  pipe,
  pick, isNil, defaultTo, head, has, map, fromPairs, isEmpty
} from 'ramda'
import toSnakeCase from './toSnakeCase';
import {parseParams} from './url';

export const getDataFromState = curry((name, sub, state) => {
  if (sub) {
    return {
      loading: path([name, sub, 'loading'], state),
      success: path([name, sub, 'success'], state),
      failed: path([name, sub, 'failed'], state),
      error: path([name, sub, 'error'], state),
      data: path([name, sub, 'data'], state)
    }
  }
  return {
    loading: path([name, 'loading'], state),
    success: path([name, 'success'], state),
    failed: path([name, 'failed'], state),
    error: path([name, 'error'], state),
    data: path([name, 'data'], state)
  }
})

export const getItemFromTree = (arr, target) => {
  for (let i in arr) {
    const a = arr[i]
    if (a.id === target) {
      return a
    }
    if (Array.isArray(a.children)) {
      const child = getItemFromTree(a.children, target)
      if (child !== null) {
        return child
      }
    }
  }
  return null
}

export const getParamsFormHistory = compose(
  parseParams,
  path(['location', 'search'])
)

export const getParams = (history, keys) =>
  pipe(
    getParamsFormHistory,
    pick(keys)
  )(history)

export const getListParams = (props, keys) =>
  pipe(
    prop('history'),
    getParamsFormHistory,
    pick(keys),
    toSnakeCase
  )(props)


const isObject = obj => typeof obj === 'object'
const isNotObject = obj => typeof obj !== 'object'
const isNotEmptyObj = obj => isObject(obj) && !isEmpty(obj)
const filterArray = filter(item => isNotObject(item) || isNotEmptyObj(item))

export const getSerializedData = (fields, data) => {
  return compose(
      fromPairs,
      map(key => {
        const defaultValue = prop(key, data)
        const isArray = Array.isArray(defaultValue)
        if (isArray) {
          const arrValue = filterArray(defaultValue)
          const hasId = pipe(
              head,
              defaultTo({}),
              has('id')
          )(arrValue)
          if (hasId) return [key, arrValue.map(prop('id'))]
          return [key, arrValue]
        }
        const values = pathOr(defaultValue, [key, 'id'], data)
        if (isNil(values)) {
          return [key, undefined]
        }
        return [key, values]
      })
  )(fields)
}
export const get2D = num => {
  if (num.toString().length < 2) {
    return '0' + num
  }
  return num.toString()
}

export const getDuration = seconds => {
  const secs = get2D(seconds % 60)
  const mins = get2D(Math.floor(seconds / 60))

  return `${mins}:${secs}`
}
