import moment from 'moment';
import storage from './storage';
import 'moment/locale/ru'
import 'moment/locale/uz'

const dateTimeFormat = (date, defaultText) => {
	const local = storage('language').get() || 'ru';
	return (date) ? moment(date).locale(local).format('DD MMM YYYY, HH:mm') : defaultText;
};

export default dateTimeFormat;
