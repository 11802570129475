import React from 'react';
import Container from 'components/Container';
import styled from 'styled-components';
import {BasicBar} from 'components/Navigation/TopBar/BasicBar';
import logo from 'media/logo.svg';
import logout from 'media/logout.svg';
import plus from 'media/plus.svg';
import PropTypes from 'prop-types';
import {path} from 'ramda';
import card from 'media/card.svg';
import mapIndexed from 'helpers/mapIndexed';
import {useTranslate} from 'helpers/translate';
import {ChevronRight} from 'react-feather';
import {HISTORY_PATH, ROOT_PATH} from 'constants/routes';
import {useHistory} from 'react-router-dom';

const Bar = styled(BasicBar)`
  height: 75px;
  padding: 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: #02142a;
`

const Right = styled('div')`
  margin-left: 10px;
`

const Photo = styled('div')`
  width: 74px;
  height: 74px;
  border-radius: 0 25px 25px 0;
  margin-left: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #FFFFFF;

  & > img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
`

const Name = styled('p')`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: -0.5px;

  color: #FFFFFF;

`

const Username = styled('p')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.005em;
  color: #CED7F5;
  mix-blend-mode: normal;
`

const Menus = styled('div')`
  width: 100%;
  height: max-content;
`

const Menu = styled('div')`
  width: 100%;
  display: flex;
  height: 54px;
  align-items: center;
  border-bottom: 1px solid #DFE0E3;

  & p {
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 135%;
    letter-spacing: -0.005em;
    color: #2E384C;
    mix-blend-mode: normal;
  }

  position: relative;

  & > :last-child {
    position: absolute;

    right: 0;
  }
`

const ProfileGrid = (props) => {
  const {t} = useTranslate()
  const history = useHistory()
  const {user} = props
  const firstName = path(['user', 'firstName'], user)
  const lastName = path(['user', 'lastName'], user)
  const username = path(['user', 'username'], user)

  const menus = [
    {
      title: 'my_orders',
      icon: card,
      onClick: () => history.push(HISTORY_PATH)
    },
    {
      title: 'pending_orders',
      icon: card,
      onClick: () => history.push({
        pathname: HISTORY_PATH,
        search: '?status=pending'
      })
    },
    {
      title: 'create_order',
      icon: plus,
      onClick: () => history.push(ROOT_PATH)
    },
    {
      title: 'exit',
      icon: logout,
      onClick: () => window.Telegram.WebApp.close()
    }
  ]
  return (
    <Container
      top={'74px'}
      hasStatusBar
    >
      <Bar>
        <Photo>
          <img src={logo} alt={''}/>
        </Photo>
        <Right>
          <Name>
            {firstName} {lastName}
          </Name>
          <Username>
            {username}
          </Username>
        </Right>
      </Bar>
      <Menus>
        {
          mapIndexed((item) => {
            const {title, icon, onClick} = item
            return (
              <Menu onClick={onClick && onClick}>
                <img src={icon} alt={''}/>
                <p>{t(title)}</p>
                <ChevronRight size={20} stroke={'#B8BBC2'}/>
              </Menu>
            )
          }, menus)
        }
      </Menus>
    </Container>
  )
}

ProfileGrid.propTypes = {
  user: PropTypes.object.isRequired
}

export default ProfileGrid
