import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import mapIndexed from 'helpers/mapIndexed';
import {filter, isEmpty, prop} from 'ramda';
import storage from 'helpers/storage';

const Wrapper = styled('div')`
  width: 100%;
  border-bottom: 1px solid #E4E4E4;
  padding-bottom: 20px;
`

const Header = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > :first-child {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #282828;
  }

  & > :last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    color: #2934D0;
  }

  margin-bottom: 10px;
`

const Content = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`

const Item = styled('span')`
  margin: 4px 0;
  font-style: normal;
  transition: all ease .1s;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  cursor: pointer;
  color: ${({active}) => active ? '#FFFFFF' : '#69707E'};
  max-width: max-content;
  padding: 8.5px 14px;
  background: ${({active}) => active ? '#2934D0' : '#ffffff'};
  border: 1px solid rgba(40, 40, 40, 0.16);
  border-radius: 28px;
  min-width: max-content;
`

const Select = (props) => {
  const {data, title, selects, setSelects, storageName, styles} = props

  const onClick = (id, name) => {
    const isExist = filter(item => {
      return prop('id', item) === id
    }, selects)
    const isActive = !isEmpty(isExist)

    if (isActive) {
      const removed = filter(item => {
        return prop('id', item) !== id
      }, selects)
      setSelects(removed)
    } else {
      setSelects([...selects, {id, name}])
    }
  }

  useEffect(() => {
    storage(storageName).set(selects)
  }, [selects])

  return (
    <Wrapper style={styles}>
      <Header>
        <p>{title}</p>
        <p>Посмотреть всё</p>
      </Header>
      <Content>
        {
          mapIndexed(item => {
            const name = prop('name', item)
            const id = prop('id', item)
            const isExist = filter(item => {
              return prop('id', item) === id
            }, selects)
            const isActive = !isEmpty(isExist)
            return (
              <Item active={isActive} onClick={() => onClick(id, name)}>
                {name}
              </Item>
            )
          }, data)
        }
      </Content>
    </Wrapper>
  )
}

Select.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
}

export default Select
