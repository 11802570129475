import React, {useEffect} from 'react'
import styled from 'styled-components'
import {forEachObjIndexed, is, path} from 'ramda'
import PropTypes from 'prop-types'
import {X as ExitIcon} from 'react-feather'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {closeErrorAction} from 'actions/error'
import {TextStatus} from 'components/Status/TextStatus'

const BGMusk = styled('div')`
  background: ${({theme}) => theme.background.mask};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${({isOpen}) => isOpen ? '1' : '0'};
  transition: all .2s ease-out;
  visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
  z-index: 10000;
  margin: 0 !important;
`
const ModalContent = styled('div')`
  background: #fff;
  border-radius: 12px;
  padding: 18px 16px;
  display: ${({isOpen}) => isOpen ? 'flex' : 'none'};
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  transition: all .2s ease-out;
  transform: translateX(-50%) translateY(-50%);
  max-width: 375px;
  width: 95%;
  z-index: 1000000;
  max-height: 70vh;
`
const Title = styled('h4')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: ${({theme}) => theme.palette.black};
  h2{
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }
`
const TitleWrap = styled('div')`
  min-height: 35px;
  margin-right: 50px;
`
const ExitIconWrap = styled('span')`
  position: absolute;
  right: 16px;
  top: 17px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #e6e6e7;
  svg{
    color: rgba(130, 130, 130, 0.85);
  }
`
const styles = {
  display:'block',
  overflowY:'auto',
  lineHeight:'20px'
}

let errors = ''

const showErrors = (error, label) => {
  if (is(Object, error) || is(Array, error)) {
    forEachObjIndexed((value, key) => {
      showErrors(value, isNaN(Number(key)) ? key : label)
    }, error || [])
  } else {
    errors += label ? label + ': ' : ''
    errors += error
  }

  if (errors !== 'null') {
    return errors
  } else {
    return null
  }
}

const enhance = compose(
  connect(state => {
    const open = path(['error', 'open'], state)
    const message = path(['error', 'message'], state)
    const errMessage = path(['error', 'errMessage'], state)
    return {
      open,
      message,
      errMessage
    }
  })
)

const ModalError = props => {
  const {
    open,
    title = 'Что-то пошло не так',
    dispatch,
    message,
    errMessage
  } = props

  // Handlers
  const close = () => dispatch(closeErrorAction())

  // Const
  const bug = message || showErrors(errMessage) || 'Проверьте соединение с интернетом'
  errors = ''

  // useEffect
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style = null
    }
  }, [open])

  return (
    <>
      <BGMusk isOpen={open} onClick={close} />
      <ModalContent isOpen={open}>
        <TitleWrap>
          {title && <Title>{title}</Title>}
          <div>
            <ExitIconWrap onClick={close}>
              <ExitIcon size={16} />
            </ExitIconWrap>
          </div>
        </TitleWrap>
        <TextStatus
          status={'inactive'}
          styles={styles}
        >
          {bug}
        </TextStatus>
      </ModalContent>
    </>
  )
}

ModalError.propTypes = {
  title:PropTypes.any,
  open:PropTypes.bool
}

export default enhance(ModalError)
