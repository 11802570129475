import React from 'react'
import { HashRouter as Router, Switch } from 'react-router-dom'
import { Route } from 'react-router'
import routes from 'routes'
import MultiRouter from 'routes/MultiRouter/MultiRouter'
import NotFoundContainer from 'components/Pages/NotFound';

// Component
const RouterProvider = () => {
  return (
    <Router>
      <Switch>
        {routes.map((route, key) => (
          <MultiRouter key={key} {...route} />
        ))}
        <Route component={NotFoundContainer} />
      </Switch>
    </Router>
  )
}

export default RouterProvider
