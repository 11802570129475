import axios from 'helpers/axios';
import * as API from 'constants/api'
import * as ACTION_TYPES from 'redux/types'
import {map, path, pathOr, prop} from 'ramda';
import sprintf from 'sprintf';

export const productsListFetchAction = (brands, price, page, data, showMore) => {

  const store = window.store.getState()
  const products = showMore ? pathOr([], ['products', 'list', 'data', 'results'], store) : []
  const ids = brands && map(prop('id'))(brands)
  const telegram = window.Telegram.WebApp.initDataUnsafe
  const telegram_auth = window.Telegram.WebApp.initData
  const search = prop('search', data) ? decodeURI(prop('search', data)) : prop('search', data)
  const params = {
    'page_size': 24,
    'page': page,
    'thumbnail_type': 'large',
    'brands': ids.join('-'),
    search: search
  }

  const payload = axios()
    .post(API.PRODUCTS_LIST, {telegram,telegram_auth}, {params})
    .then((response) => {
      return {
        ...path(['data'], response),
        results: [...products, ...path(['data', 'results'], response)]
      }
    })
    .catch((error) => {
      return Promise.reject(path(['response', 'data'], error))
    })

  return {
    type: ACTION_TYPES.MAIN_PRODUCTS_LIST_FETCH_ACTION,
    payload
  }
}

export const productsItemFetchAction = (id) => {
  const payload = axios()
    .post(sprintf(API.HISTORY_ITEM, id))
    .then((response) => {
      return path(['data'], response)
    })
    .catch((error) => {
      return Promise.reject(path(['response', 'data'], error))
    })

  return {
    type: ACTION_TYPES.MAIN_PRODUCTS_ITEM_FETCH_ACTION,
    payload
  }
}
