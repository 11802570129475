const main = {
  my_orders: {
    en: 'My orders',
    ru: 'Мои заказы',
    uz: 'Buyurtmalarim'
  },
  pending_orders: {
    en: 'Заказы в ожидании',
    ru: 'Заказы в ожидании',
    uz: 'Заказы в ожидании'
  },
  exit: {
    en: 'Exit',
    ru: 'Выход',
    uz: 'Chiqish'
  },
  create_order: {
    en: 'Create  order',
    ru: 'Создать заказ',
    uz: 'Buyurtma berish'
  },
  order: {
    en: 'Order № ',
    ru: 'Заказ № ',
    uz: 'Buyurtma № '
  },
  search_products: {
    en: 'Search products %s',
    ru: 'Поиск товаров %s',
    uz: 'Mahsulot qidirish %s'
  },
  add_to_cart: {
    en: 'Add to cart',
    ru: 'В корзину',
    uz: 'Savatga qo\'shish'
  },
  delete_cart: {
    en: 'Delete',
    ru: 'Удалить',
    uz: 'O\'chirish'
  },
  close: {
    en: 'Close',
    ru: 'Закрывать',
    uz: 'Yopish'
  },
  created_date: {
    en: 'Created date',
    ru: 'Дата создания',
    uz: 'Yaratilgan sana'
  },
  product_not_found: {
    en: 'Product not found',
    ru: 'Продукт не найден',
    uz: 'Mahsulot topilmadi'
  },
  address_not_found: {
    en: 'Address not found',
    ru: 'Адреса не найдены',
    uz: 'Manzillar topilmadi'
  },
  indicate_map: {
    en: 'Indicate on the map',
    ru: 'Указать на карте',
    uz: 'Xaritada ko\'rsating'
  },
  basket: {
    en: 'Basket',
    ru: 'Корзина',
    uz: 'Savat'
  },
  type: {
    en: 'Type',
    ru: 'Тип',
    uz: 'Turi'
  },
  not_specified: {
    en: 'Not specified',
    ru: 'Не указано',
    uz: 'Ko\'rsatilmagan'
  },
  available: {
    en: 'Available',
    ru: 'Доступно',
    uz: 'Mavjud'
  },
  brand: {
    en: 'Brand',
    ru: 'Бренд',
    uz: 'Brend'
  },
  enter_the_amount: {
    en: 'Enter the amount',
    ru: 'Введите количество',
    uz: 'Miqdorni kiriting'
  },
  order_price: {
    en: 'Order price :',
    ru: 'Сумма заказа :',
    uz: 'Buyurtma narxi :'
  },
  go_to_checkout: {
    en: 'Go to checkout',
    ru: 'Перейти к оформлению',
    uz: 'To\'lov qilish'
  },
  making_order: {
    en: 'Making an order',
    ru: 'Оформление заказа',
    uz: 'Buyurtma berish'
  },
  authorization: {
    en: 'Authorization',
    ru: 'Авторизация',
    uz: 'Avtorizatsiya'
  },
  save: {
    en: 'Save',
    ru: 'Оформить',
    uz: 'Saqlash'
  },
  continue: {
    en: 'Continue',
    ru: 'Продолжить',
    uz: 'Davom eting'
  },
  add_address: {
    en: 'Add an address',
    ru: 'Добавить адрес',
    uz: 'Manzil qo\'shish'
  },
  order_another_address: {
    en: 'Order to another address',
    ru: 'Заказать на другой адрес',
    uz: 'Boshqa manzilga buyurtma bering'
  },
  house: {
    en: 'house',
    ru: 'дом',
    uz: 'uy'
  },
  entrance: {
    en: 'entrance',
    ru: 'подъезд',
    uz: 'kirish '
  },
  floor: {
    en: 'floor',
    ru: 'этаж',
    uz: 'qavat'
  },
  apartment: {
    en: 'apartment',
    ru: 'квартира',
    uz: 'kvartira'
  },
  address_delivery: {
    en: 'The address for delivery',
    ru: 'Адрес для доставки',
    uz: 'Yetkazib berish manzili'
  },
  filter: {
    en: 'Filter',
    ru: 'Фильтры',
    uz: 'Filtr'
  },
  clear: {
    en: 'Clear',
    ru: 'Очистить',
    uz: 'Tozalash'
  }
}

export default main
