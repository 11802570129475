import HistoryDetailGrid from './Grid';
import useFetch from 'hooks/useFetch';
import {historyItemFetchAction} from 'actions/history';
import * as STATES from 'redux/reducers/states';
import {useParams} from 'react-router-dom';

const HistoryDetail = () => {
  const {id} = useParams();

  const detail = useFetch({
    'action': () => historyItemFetchAction(id),
    'stateName': STATES.HISTORY,
    'child': 'item'
  })
  return (
    <HistoryDetailGrid
      id={id}
      detail={detail}
    />
  );
};

export default HistoryDetail;
