import React, {useState} from 'react';
import AddressField from "./AddressField";
import YandexMap from "../../Fields/Map/YandexMap";
import {prop} from "ramda";
import Modal from "../../Modal/Modal";
import styled from "styled-components";
import {Form as FinalForm} from 'react-final-form'
import Fields from "../../Fields/Fields";
import useCreate from "../../../hooks/useCreate";
import {getSerializedData} from "../../../helpers/get";
import * as STATES from "../../../redux/reducers/states";
import {addressCreateAction, addressListFetchAction} from "../../../actions/adress";
import {useDispatch} from "react-redux";
import {useTranslate} from "../../../helpers/translate";


const Container = styled.div`
    height: 70vh;
    overflow: auto;
`
const addressFields = [
    'location',
    'name',
    'building',
    'entrance',
    'floor',
    'room',
    'comment',
]
const FieldsAdres = ({fields, step, setStep, loading}) => {
  const addressInputs = prop('address', fields)
  const locationInputs = prop('location', fields)
  const onAddressChange = addressInputs.input.onChange
  const addressValue = addressInputs.input.value

  return (
      <Container>
        {step === 1 ? (
            <AddressField
                name={'address'}
                loading={loading}
                input={addressInputs.input}
                locationInputs={locationInputs.input}
                onIconClick={() => setStep(0)}
            />
        ) : (
            <YandexMap
                {...locationInputs}
                onToggle={() => setStep(1)}
                addressValue={addressValue}
                onAddressChange={onAddressChange}
            />
        )}
      </Container>
      )
}

const AddAddressModal = ({open, onClose}) => {
  const {t} = useTranslate()
  const [step, setStep] = useState(0)
  const dispatch = useDispatch()

  const createAddress = useCreate({
    action: addressCreateAction,
    onSuccess: () => {
      dispatch(addressListFetchAction())
      setStep(0)
      onClose()
    },
    serializer: (values) => ({...getSerializedData(addressFields, values)}),
    stateName: STATES.CONFIRM_PHONE,
    childIsNull: true
  })
  return (
      <>
        <Modal
            open={open}
            onClose={onClose}
            maxWidth={'90%'}
            title={t('indicate_map')}
            showHeader={true}
            modalStyles={{
              margin: 0,
              top: '78px',
              position: 'relative',
              transform: 'translateY(-50%) !important',
            }}
        >
          <FinalForm
              onSubmit={createAddress.onSubmit}
              render={({handleSubmit, values, form, ...formProps}) => {
                return (
                    <form onSubmit={handleSubmit} style={{height: '100%'}}>
                      <Fields
                          names={[
                            'location',
                            'address',
                          ]}
                      >
                        {(fields) => {
                          return <FieldsAdres fields={fields} step={step} setStep={setStep} loading={createAddress.loading} />
                        }}
                      </Fields>
                    </form>
                )
              }}
          />

        </Modal>
      </>
  );
};

export default AddAddressModal;
