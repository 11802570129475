/* eslint-disable max-len */
import React from 'react'
import SvgIcon from "../SvgIcon";


const Pin = ({ color, ...props }) => {
  return (
    <SvgIcon fill={color} viewBox={'0 0 36 74'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
      <path d={'M35.52 17.76C35.52 27.5686 27.5686 35.52 17.76 35.52C7.95142 35.52 0 27.5686 0 17.76C0 7.95142 7.95142 0 17.76 0C27.5686 0 35.52 7.95142 35.52 17.76Z'} fill={'white'} />
      <path fillRule={'evenodd'} clipRule={'evenodd'} d={'M17.76 22.8343C20.5625 22.8343 22.8343 20.5625 22.8343 17.76C22.8343 14.9575 20.5625 12.6857 17.76 12.6857C14.9575 12.6857 12.6857 14.9575 12.6857 17.76C12.6857 20.5625 14.9575 22.8343 17.76 22.8343ZM17.76 35.52C27.5686 35.52 35.52 27.5686 35.52 17.76C35.52 7.95142 27.5686 0 17.76 0C7.95142 0 0 7.95142 0 17.76C0 27.5686 7.95142 35.52 17.76 35.52Z'} fill={'#F43F3C'} />
      <path d={'M14.7998 34.04H20.7198V68.82H14.7998V34.04Z'} fill={'#F43F3C'} />
      <path d={'M25.1604 71.4101C25.1604 72.8405 21.8473 74.0001 17.7604 74.0001C13.6734 74.0001 10.3604 72.8405 10.3604 71.4101C10.3604 69.9797 13.6734 68.8201 17.7604 68.8201C21.8473 68.8201 25.1604 69.9797 25.1604 71.4101Z'} fill={'#333333'} />
    </SvgIcon>
  )
}
export default Pin
