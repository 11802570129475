import React from 'react'
import styled from 'styled-components'
import {  map } from 'ramda'
import { Field } from 'react-final-form'
import RadioBlockField from "../../Fields/radio/RadioBlockField";

const Title = styled('h4')`
  font-size: ${({ theme }) => theme.fontSize.title};
  font-weight: 500;
  margin-bottom: 10px;
`

const Grid = styled.div`
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
`

const AddressesField = ({ name, options, title }) => {
  return (
    <>
      {title && <Title>{title}</Title>}
      <Grid>
        {map(
            ({ id, disabled, ...item }) => (
                <Field
                    key={id}
                    valueType={'text'}
                    disabledOption={disabled}
                    value={id}
                    defaultValue={'6'}
                    id={id}
                    {...item}
                    component={RadioBlockField}
                    type={'radio'}
                    name={name}
                />
            ),
            options.filter(Boolean)
        )}
      </Grid>
    </>
  )
}

export default AddressesField
