import {applyMiddleware, createStore} from 'redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import {createLogger} from 'redux-logger'
import rootReducer from 'redux/reducers'

const store = (initialState = {}) => {
  const middleware = [thunk, promise]

  if (process.env.NODE_ENV === 'development') {
    middleware.push(createLogger({collapsed: true}))
  }

  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware)
  )
  window.store = store

  return store
}

export default store
