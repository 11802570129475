import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {prop} from 'ramda';
import {BasicBar} from 'components/Navigation/TopBar/BasicBar';

const Wrap = styled(BasicBar)`
  height: 55px;
  padding: 15px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`

const Bar = styled('div')`
  width: 55px;
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: ${({theme}) => theme.palette.black};

  svg:first-child {
    margin-left: -5px;
    color: ${({theme}) => theme.palette.black};
  }
`

const MiddleBar = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px 0 33px;
  width: 100%;
`

const MainBar = props => {
  const {
    firstBtn,
    lastBtn,
    title
  } = props
  const firstIcon = prop('icon', firstBtn)
  const firstIconClick = prop('onClick', firstBtn)
  const lastIcon = prop('icon', lastBtn)
  const lastIconClick = prop('onClick', lastBtn)
  return (
    <Wrap>
      <Bar
        style={{
          left: '0'
        }}
        onClick={firstIconClick && firstIconClick}
        type={'button'}>
        {firstIcon}
      </Bar>
      <MiddleBar>
        {title}
      </MiddleBar>
      <Bar
        style={{
          right: '0'
        }}
        onClick={lastIconClick && lastIconClick}>
        {lastIcon}
      </Bar>
    </Wrap>
  )
}

MainBar.propTypes = {
  firstBtn: PropTypes.shape({
    icon: PropTypes.element.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  lastBtn: PropTypes.shape({
    icon: PropTypes.element.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  title: PropTypes.any.isRequired
}

export default MainBar
