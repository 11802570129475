import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from 'components/Navigation/Components/Button';
import {Edit2, Minus, Plus, Trash2, X} from 'react-feather';
import {path} from 'ramda';
import noPhoto from 'media/no-photo.jpeg';
import useCard from 'hooks/useCard';
import formatNumber from 'helpers/formatNumber';
import {bottomSheet} from 'react-simple-bottom-sheet';
import SetAmount from 'components/SetAmount';
import {useTranslate} from 'helpers/translate';
import Detail from 'components/Detail';

const Wrapper = styled('div')`
  height: 265px;
  background: white;
  padding: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  max-width: 186px;
  position: relative;
  border-radius: 10px;
  width: ${({width}) => width || 'max-content'};
  margin: auto;
`

const Image = styled('div')`
  width: 100%;
  height: 140px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 10px;

  background: #FFFFFF;
  border: 0.5px solid #E4E5EC;
  border-radius: 6px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Content = styled('div')`
  width: 100%;
  padding: 0 10px;
  margin-top: 10px;
`

const Price = styled('div')`
  display: flex;
  margin-top: 6px;
  align-items: center;

  & * {
    font-family: var(--medium);
  }

  & p {
    font-size: 15px;
    line-height: 14px;
    text-align: right;
    color: #252325;
  }
`

const Name = styled('div')`
  margin-bottom: 5px;

  & * {
    font-family: var(--light);
  }

  & p {
    font-size: 14px;
    line-height: 23px;
    color: #252325;
    mix-blend-mode: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

  }
`

const Fixed = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  height: max-content;
  width: 100%;
`

const Calc = styled('div')`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const Label = styled('div')`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  & input {
    max-width: 60px;
    border: none;
    outline: none;
    height: 45px;
    text-align: center;
  }

  & p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    text-align: center;
    color: #000000;
  }
`

const Header = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: -10px;
  
  .flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  & p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.5px;
    color: ${({link}) => link ? '#2934D0' : '#282828'};

  }
`

const EditIcon = styled('div')`
  width: 30px;
  height: 30px;
  background: #2934D0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
`

const Card = (props) => {
  const {width, data, isOrder, onDelete} = props;
  const {handleAdd, exist, amount, handleRemove, handleChange} = useCard(data);
  const { t } = useTranslate()

  // Data
  const image = path(['image', 'file'], data);
  const name = path(['name'], data);
  const cashPrice = path(['cashPrice'], data);
  const measurement = path(['measurement', 'name'], data);
  const currency = path(['currency', 'sign'], data);


  // const handleOpen = () => {
  //   document.querySelector('body').classList.add('hidden')
  //   bottomSheet.close({id: 'detail'})
  //   bottomSheet.create({
  //     content: <SetAmount
  //       onDelete={() => {
  //         handleRemove(amount * -1)
  //         bottomSheet.close({id: 'set-amount'})
  //         document.querySelector('body').classList.remove('hidden')
  //       }}
  //       amount={amount === null || amount === 0 ? null : amount}
  //     />,
  //     id: 'set-amount',
  //     dragHandle: ['mask'],
  //     header: headerContent,
  //     footer: footerContent
  //   });
  // };

  const openDetail = () => {
      document.querySelector('body').classList.add('hidden')
      bottomSheet.close({id: 'set-amount'})
      bottomSheet.create({
          content: <Detail
              isOrder={isOrder}
              data={data}
          />,
          id: 'detail',
          dragHandle: ['mask'],
          header: detailHeaderContent
      })
  }

  // const headerContent = (
  //   <Header link>
  //     <p onClick={openDetail}>{name}</p>
  //     {exist ?
  //       <X size={22} onClick={() => {
  //         bottomSheet.close({id: 'set-amount'})
  //         document.querySelector('body').classList.remove('hidden')
  //       }}/>
  //       : null
  //     }
  //   </Header>
  // );
  //
  // const footerContent = (
  //   <Button
  //     background={'#4c956c'}
  //     color={'#ffffff'}
  //     height={'45px'}
  //     onClick={() => {
  //       handleChange(window.productAmount === 0 ? 0 : window.productAmount || amount)
  //       bottomSheet.close({id: 'set-amount'})
  //       document.querySelector('body').classList.remove('hidden')
  //     }}
  //     title={t('add_to_cart')}
  //   />
  // );

  const detailHeaderContent = (
    <Header>
      <div className={'flex'}>
          <p>{name}</p>
          <X size={20} onClick={() => {
              bottomSheet.close({id: 'detail'})
              document.querySelector('body').classList.remove('hidden')
          }}/>
      </div>
        <div className={'flex'} style={{marginTop: '10px'}}>
            <SetAmount
                isOrder={isOrder}
                isDetail
                onDelete={() => {
                    onDelete && onDelete()
                    handleRemove(amount * -1)
                    bottomSheet.close({id: 'detail'})
                    document.querySelector('body').classList.remove('hidden')
                }}
                amount={amount === null || amount === 0 ? null : amount}
            />
            <Button
                background={'#4c956c'}
                color={'#ffffff'}
                height={'45px'}
                onClick={() => {
                    handleChange(window.productAmount === 0 ? 0 : window.productAmount || amount)
                    bottomSheet.close({id: 'detail'})
                    document.querySelector('body').classList.remove('hidden')
                }}
                title={t('add_to_cart')}
            />
        </div>
    </Header>
  );

  const detailFooterContent = (
    <Button
      background={'#4c956c'}
      color={'#ffffff'}
      height={'45px'}
      onClick={() => {
        handleChange(window.productAmount === 0 ? 0 : window.productAmount || amount)
        bottomSheet.close({id: 'detail'})
        document.querySelector('body').classList.remove('hidden')
      }}
      title={t('add_to_cart')}
    />
  );

  return (
    <Wrapper width={width}>
      <Image onClick={openDetail}>
        {exist ?
          <EditIcon onClick={openDetail}>
            <Edit2 stroke={'#ffffff'} size={14}/>
          </EditIcon>
          : null
        }
        <img src={image || noPhoto} alt={''}/>
      </Image>
      <Content>
        <Name onClick={openDetail}>
          <p>
            {name}
          </p>
        </Name>
        <Price>
          <p>{formatNumber(cashPrice, `${currency}`, ' ')}</p>
        </Price>
      </Content>
      <Fixed>
        {
          !exist ?
            <Button
              height={'30px'}
              fontSize={'14px'}
              onClick={() => handleChange(amount || 1)}
              title={t('add_to_cart')}
            />
            : null
        }
        {
          exist ?
            <Calc>
              {
                amount === 1 ?
                  <Button
                    onClick={() => handleRemove()}
                    title={<Trash2 stroke={'#F43F3C'} size={18}/>}
                    width={'30px'}
                    height={'30px'}
                    background={'#F2F2F2'}
                  />
                  :
                  <Button
                    onClick={() => handleRemove()}
                    title={<Minus stroke={'#2934D0'} size={18}/>}
                    width={'30px'}
                    height={'30px'}
                    background={'#F2F2F2'}
                  />
              }
              <Label onClick={openDetail}>
                <p>{formatNumber(amount, measurement, ' ')}</p>
              </Label>
              <Button
                onClick={() => handleAdd()}
                title={<Plus stroke={'#2934D0'} size={18}/>}
                width={'30px'}
                height={'30px'}
                background={'#F2F2F2'}
              />
            </Calc>
            : null
        }
      </Fixed>
    </Wrapper>
  )
}

Card.propTypes = {
  width: PropTypes.string,
  data: PropTypes.object
}

export default Card
