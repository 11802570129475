import React from 'react';
import styled from 'styled-components';
import Button from 'components/Navigation/Components/Button';
import {Edit2, X} from 'react-feather';
import PropTypes from 'prop-types';
import {path, pathOr} from 'ramda';
import noPhoto from 'media/no-photo.jpeg';
import formatNumber from 'helpers/formatNumber';
import {bottomSheet} from 'react-simple-bottom-sheet';
import SetAmount from 'components/SetAmount';
import {useTranslate} from 'helpers/translate';
import Detail from 'components/Detail';

const Wrapper = styled('div')`
  margin-top: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  position: relative;
  border-radius: 10px;
  padding: 15px;
  width: ${({width}) => width || '100%'};
  background: #FFFFFF;
`

const Content = styled('div')`
  width: 100%;
  display: flex;
  align-items: flex-start;
`

const Image = styled('div')`
  min-width: 51px;
  overflow: hidden;
  max-width: 51px;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 10px;

  background: #FFFFFF;
  border: 0.5px solid #E4E5EC;
  border-radius: 6px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Price = styled('div')`
  display: flex;
  margin-top: 6px;
  align-items: center;

  & * {
    font-family: var(--medium);
  }

  & > :not(:last-child) {
    margin-right: 4px;
  }

  & p {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 14px;
    text-align: right;
    color: #252325;
  }
`

const Right = styled('div')`
  width: 100%;
`

const Name = styled('div')`
  margin-bottom: 5px;

  & * {
    font-family: var(--light);
  }

  & p {
    font-size: 14px;
    line-height: 23px;
    color: #252325;
    mix-blend-mode: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

  }
`

const Header = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: -10px;

  .flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  & p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.5px;
    color: ${({link}) => link ? '#2934D0' : '#282828'};

  }
`

const Bottom = styled('span')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Amount = styled('div')`
  width: max-content;
  display: flex;
  align-items: center;

  & > :last-child {
    margin-left: 8px;
  }
`

const EditIcon = styled('div')`
  width: 30px;
  height: 25px;
  border-radius: 5px;
  background: #2934D0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ListCard2 = (props) => {
    const {key, data, onDelete, onChange, customCurrency} = props

    const {t} = useTranslate()
    // Data
    const image = path(['image', 'file'], data)
    const amount = Number(path(['amount'], data))
    const id = Number(path(['id'], data))
    const name = path(['name'], data)
    const cashPrice = path(['cashPrice'], data)
    const currency = pathOr(customCurrency, ['currency', 'name'], data);
    const measurement = path(['measurement', 'name'], data);

    const openDetail = () => {
        document.querySelector('body').classList.add('hidden')
        bottomSheet.close({id: 'set-amount'})
        bottomSheet.create({
            content: <Detail
                data={data}
            />,
            id: 'detail',
            dragHandle: ['mask'],
            header: detailHeaderContent
        })
    }

    const detailHeaderContent = (
        <Header>
            <div className={'flex'}>
                <p>{name}</p>
                <X size={20} onClick={() => {
                    bottomSheet.close({id: 'detail'})
                    document.querySelector('body').classList.remove('hidden')
                }}/>
            </div>
            <div className={'flex'} style={{marginTop: '10px'}}>
                <SetAmount
                    isDetail
                    onDelete={() => {
                        onDelete && onDelete(id)
                        bottomSheet.close({id: 'detail'})
                        document.querySelector('body').classList.remove('hidden')
                    }}
                    amount={amount === null || amount === 0 ? null : amount}
                />
                <Button
                    background={'#4c956c'}
                    color={'#ffffff'}
                    height={'45px'}
                    onClick={() => {
                        onChange(window.productAmount === 0 ? 0 : window.productAmount || amount, id)
                        bottomSheet.close({id: 'detail'})
                        document.querySelector('body').classList.remove('hidden')
                    }}
                    title={t('add_to_cart')}
                />
            </div>
        </Header>
    );

    if (Number(amount) <= 0) {
        return <></>
    }
    return (
        <Wrapper key={key}>
            <Content>
                <Image onClick={openDetail}>
                    <img src={image || noPhoto} alt={''}/>
                </Image>
                <Right>
                    <Name onClick={openDetail}>
                        <p>
                            {name}
                        </p>
                    </Name>
                    <Bottom>
                        <Price>
                            <p>{formatNumber(cashPrice, `${currency}`, ' ')}</p>
                        </Price>
                        {!amount ? <Button
                                onClick={openDetail}
                                style={{
                                    fontSize: '11px !important',
                                    padding: '6px 0',
                                    fontWeight: '700',
                                    fontFamily: '"Medium", sans-serif !important'
                                }}
                                title={t('add_to_cart')}
                                width={'75px'}
                                height={'25px'}
                            />
                            : <Amount>
                                {formatNumber(amount, measurement)}
                                <EditIcon onClick={openDetail}>
                                    <Edit2 stroke={'#ffffff'} size={14}/>
                                </EditIcon>
                            </Amount>
                        }
                    </Bottom>
                </Right>
            </Content>
        </Wrapper>
    );
};

ListCard2.propTypes = {
    key: PropTypes.any
}

export default ListCard2;
