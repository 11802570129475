import React from 'react';
import PhoneNumberField from "./PhoneNumberField";

export const fieldsAuth =[
 'phone'
]
const Auth = () => {
  return (
      <PhoneNumberField name={'phone'}/>
  );
};

export default Auth;
