import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {compose} from 'recompose';
import {path, prop, sum} from "ramda";
import {productsItemFetchAction} from 'actions/products';
import EditOrderGrid from './Grid';
import mapIndexed from "../../../helpers/mapIndexed";
import {historyItemConfirm} from "../../../actions/history";
import {HISTORY_PATH} from "../../../constants/routes";
import {useHistory} from "react-router";
import {orderQuickEditSerializer} from "./editOrderSerializer";

const enhance = compose(
    connect((state) => {
        const orderDetail = path(['products', 'item'], state)
        return {
            orderDetail
        }
    })
)

const EditOrderContainer = (props) => {
    const {orderDetail} = props
    const history = useHistory()
    const params = path(['match', 'params'], props)
    const dispatch = useDispatch()
    const id = Number(path(['id'], params))
    const loading = path(['loading'], orderDetail)
    const detailProducts = path(['data', 'products'], orderDetail)
    const [products, setProducts] = useState()
    const currency = path(['data', 'currency', 'name'], orderDetail)
    useEffect(() => {
        setProducts(detailProducts)
    }, [detailProducts])

    // useEffect(() => {
    //     const removedProducts = products && mapIndexed((item) => {
    //         const amount = prop('id', item)
    //         if (!(Number(amount) <= 0)) {
    //             return item
    //         }
    //     }, products)
    //     setProducts(removedProducts)    }, [products])
    const deleteProduct = (id) => {
        const removedProducts = products && mapIndexed((item) => {
            const itemId = prop('id', item)
            if (Number(itemId) === Number(id)) {
                return {...item, amount: 0}
            }
            return {
                ...item
            }
        }, products)
        setProducts(removedProducts)
    }
    const onSetProduct = (amount, id) => {
        const changedProducts = products && mapIndexed((item) => {
            const itemId = prop('id', item)
            if (Number(itemId) === Number(id)) {
                return {
                    ...item,
                    amount
                }
            }
            return {
                ...item
            }
        }, products)
        setProducts(changedProducts)
    }
    const onSubmit = (values) => {
        dispatch(historyItemConfirm(id, {
            confirm_order: 'False',
            order: orderQuickEditSerializer({isConfirmed: values.isConfirmed || false, products})
        })).then(() => {
            history.push({
                pathname: HISTORY_PATH,
                search: '?status=pending'
            })
        })
    }
    const total = products ? sum(mapIndexed((item) => {
        return Number(prop('newPrice', item) * Number(prop('amount', item)))
    }, products)) : 0

    useEffect(() => {
        dispatch(productsItemFetchAction(Number(id)))
    }, [id])
    return (
        <>
            <EditOrderGrid
                currency={currency}
                onSetProduct={onSetProduct}
                onSubmit={onSubmit}
                loading={loading}
                id={id}
                deleteProduct={deleteProduct}
                products={products}
                total={total}
            />
        </>
    );
};

export default enhance(EditOrderContainer);
