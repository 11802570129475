import PropTypes from 'prop-types';
import {path, prop} from 'ramda';
import styled from 'styled-components';
import noPhoto from 'media/no-photo.jpeg';
import formatNumber from 'helpers/formatNumber';
import Description from 'components/Description';
import {useTranslate} from 'helpers/translate';

const Wrapper = styled('div')`
  width: 100%;
`

const Image = styled('div')`
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Info = styled('div')`
  padding: 10px 16px;

  & > :first-child {
    font-style: normal;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #282828;
  }

  & > :last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    letter-spacing: -0.015em;
    color: #475061;
  }
`

const Price = styled('div')`
  margin-top: 5px;
  padding: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 129.96%;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  mix-blend-mode: normal;
  color: #333333;

  & * {
    color: #333333;
  }
`

const Details = styled('div')`
  width: 100%;
  padding: 0 16px 0 16px;
`

const styles = {
  parent: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    color: '#475061'
  },
  child: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '23px',
    color: '#475061',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right'
  }
}

const Detail = (props) => {
  const {data, withAvailable} = props
  const {t} = useTranslate()
  const cashPrice = prop('cashPrice', data)
  const description = prop('description', data)
  const available = prop('available', data)
  const currency = path(['currency', 'name'], data)
  const measurement = path(['measurement', 'name'], data)
  const type = path(['type', 'name'], data)
  const brand = path(['brand', 'name'], data)
  const image = path(['image', 'file'], data)
  return (
    <Wrapper>
      <Image>
        <img alt={'img'} src={image || noPhoto}/>
      </Image>
      <Price>
        {formatNumber(cashPrice, `${currency} / ${measurement}`, ' ')}
      </Price>
      <Details>
        {Number(available) >= 0 && withAvailable ?
            <Description
                childStyles={styles.child}
                parentStyles={styles.parent}
                child={formatNumber(available, measurement)}
                line
                parent={t('available')}
            />
            : null
        }
        {brand ?
          <Description
            childStyles={styles.child}
            parentStyles={styles.parent}
            child={brand || t('not_specified')}
            line
            parent={t('brand')}
          />
          : null
        }
        {type ?
          <Description
            childStyles={styles.child}
            parentStyles={styles.parent}
            child={type || t('not_specified')}
            line
            parent={t('type')}
          />
          : null
        }
      </Details>

      {description ?
        <Info>
          <p>Описание и характеристики</p>
          <p>{description}</p>
        </Info>
        : null
      }
    </Wrapper>
  )
}

Detail.propTypes = {
  data: PropTypes.object.isRequired
}

export default Detail
