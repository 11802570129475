import React from 'react'
import SvgIcon from 'components/SvgIcons'

export default props => {
  return (
    <SvgIcon viewBox={'0 0 20 20'} {...props}>
      <path
        d="M12.3438 1.17188H11.244L11.1055 0.781641C10.9396 0.314141 10.4955 0 10 0C9.50445 0 9.06043 0.314141 8.89449 0.781641L8.75602 1.17188H7.65625C7.00902 1.17188 6.48438 1.69652 6.48438 2.34375V2.92969C6.48438 3.25328 6.74672 3.51562 7.07031 3.51562H12.9297C13.2533 3.51562 13.5156 3.25328 13.5156 2.92969V2.34375C13.5156 1.69652 12.991 1.17188 12.3438 1.17188Z"
      />
      <path
        d="M12.9005 9.40418C11.3024 7.8061 8.69812 7.80551 7.09945 9.40418C5.5007 11.0035 5.5007 13.6059 7.09945 15.2052C8.6982 16.804 11.3006 16.804 12.9005 15.2052C14.4992 13.6059 14.4992 11.0035 12.9005 9.40418ZM12.0713 13.5475C12.3002 13.7764 12.3002 14.1472 12.0713 14.3761C11.8425 14.6049 11.4716 14.6049 11.2428 14.3761L9.99996 13.1333L8.75715 14.3761C8.52828 14.6049 8.15746 14.6049 7.92859 14.3761C7.69972 14.1472 7.69972 13.7764 7.92859 13.5475L9.1714 12.3047L7.92859 11.0619C7.69972 10.833 7.69972 10.4622 7.92859 10.2333C8.15746 10.0045 8.52828 10.0045 8.75715 10.2333L9.99996 11.4761L11.2428 10.2333C11.4716 10.0045 11.8425 10.0045 12.0713 10.2333C12.3002 10.4622 12.3002 10.833 12.0713 11.0619L10.8285 12.3047L12.0713 13.5475Z"
      />
      <path
        d="M15.8594 2.34375H14.6875V2.92969C14.6875 3.89898 13.899 4.6875 12.9297 4.6875H7.07031C6.10102 4.6875 5.3125 3.89898 5.3125 2.92969V2.34375H4.14062C3.17133 2.34375 2.38281 3.13227 2.38281 4.10156V18.2422C2.38281 19.2115 3.17133 20 4.14062 20H15.8594C16.8287 20 17.6172 19.2115 17.6172 18.2422V4.10156C17.6172 3.13227 16.8287 2.34375 15.8594 2.34375ZM13.7291 16.0337C11.6726 18.089 8.32754 18.0892 6.27094 16.0337C4.215 13.9773 4.215 10.6321 6.27094 8.57562C8.32742 6.52027 11.6725 6.52027 13.7291 8.57562C15.785 10.6321 15.785 13.9772 13.7291 16.0337Z"
      />
    </SvgIcon>

  )
}
