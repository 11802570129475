import {path, prop} from 'ramda';
import {sprintf} from 'sprintf-js';

import capitalize from 'helpers/capitalize';
import locales from 'locales';
import storage from 'helpers/storage';

const LANGUAGE = 'language'

function translate(key, locale) {
  return path([key, locale], locales) || path([key, 'en'], locales)
}

export function useTranslate() {
  const locale = storage(LANGUAGE).get()

  function t(key, params) {
    return sprintf(translate(key, locale), params)
  }

  function translateData(obj, objName) {
    const name = objName + capitalize(locale)
    return prop(name, obj) || prop(objName, obj)
  }

  return {t, translateData}
}
