import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Filter as FilterIcon, X} from 'react-feather';
import PropTypes from 'prop-types';
import {filter, isEmpty} from 'ramda';
import {Portal} from 'react-portal';
import {BasicBar} from 'components/Navigation/TopBar/BasicBar';
import {useTranslate} from 'helpers/translate';

const MenuContent = styled('div')`
  background: #fff;
  display: flex;
  opacity: ${({isOpen}) => isOpen ? '1' : '0'};
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
  transform: translateY(${({isOpen}) => isOpen ? '0' : '20px'});
  z-index: 1000;
  border-radius: 10px 10px 0 0;
  transition: ${({theme}) => theme.transition.primary};
`

const MenuList = styled('div')`
  flex-grow: 1;
  overflow: auto;
  padding: 66px 12px 0 12px;
  & > form > div {
    overflow-y: auto;
    height: calc(100vh - 160px);
  }
`

const IconWrap = styled('span')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Dot = styled('span')`
  display: ${({isActive}) => isActive ? 'block' : 'none'};
  position: absolute;
  left: -8px;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  background: ${({theme}) => theme.palette.red};
`

const Wrap = styled(BasicBar)`
  height: 55px;
  padding: 15px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`

const Bar = styled('div')`
  width: max-content;
  color: #2934D0;
  height: 100%;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.icon {
    min-width: 40px;
    max-width: 40px;
    height: 30px;
    justify-content: flex-end;
  }
`

const MiddleBar = styled('span')`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #282828;
  width: 100%;
`

const FiltersBar = props => {
  const {
    children,
    isOpen,
    onClear,
    setOpen,
    withOutButton,
    initialValues
  } = props
  const {t} = useTranslate()
  // Redirects
  const filteredInitialValues = initialValues && filter(item => !!item, initialValues)
  const isActive = filteredInitialValues && !isEmpty(filteredInitialValues)

  // Switch functions
  const onMenuOpen = () => setOpen(true)
  const onMenuClose = () => setOpen(false)

  // useEffect
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style = null
    }
  }, [isOpen])


  // Render
  return (
    <>
      {!withOutButton &&
      <IconWrap>
        <Dot
          isActive={isActive}
        />
        <FilterIcon
          color={'#000'}
          size={24}
          onClick={onMenuOpen}
        />
      </IconWrap>}
      <Portal>
        <MenuContent
          isOpen={isOpen}
        >
          <Wrap>
            <Bar onClick={onClear}>
              {t('clear')}
            </Bar>
            <MiddleBar>
              {t('filter')}
            </MiddleBar>
            <Bar className={'icon'} onClick={onMenuClose}>
              <X stroke={'#282828'} size={16}/>
            </Bar>
          </Wrap>
          <MenuList>
            {children}
          </MenuList>
        </MenuContent>
      </Portal>
    </>
  )
}

FiltersBar.propTypes = {
  isOpen:PropTypes.bool.isRequired,
  setOpen:PropTypes.func.isRequired,
  withOutButton:PropTypes.bool,
  initialValues:PropTypes.object
}

export default FiltersBar
