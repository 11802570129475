import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loader from "../../Loader";

const Wrap = styled('button')`
  background: ${({background}) => background || '#fefefe'};
  color: ${({color}) => color};
  width: ${({width, fullWidth}) => fullWidth ? '100%' : width || 'max-content'};
  height: ${({height}) => height || '40px'};
  display: flex;
  justify-content: center;
  font-size: ${({fontSize}) => fontSize || '16px'} !important;
  align-items: center;
  border-radius: ${({borderRadius}) => borderRadius || '5px'};
  padding: 10px 0;
  overflow: hidden;
  transition: all .2s ease-out, color 0s;
  outline: none;
  border: none;
    &:disabled{
        opacity: 0.8;
        cursor: not-allowed;
    }
  ${({style}) => style}
`

const Button = props => {
  const {
    title,
    type,
    onClick,
    style,
    background,
    disabled,
    fontSize,
    loading,
    height,
    theme,
    width,
    fullWidth
  } = props

  const getTheme = () => {
    switch (theme) {
      case 'primary':
        return {
          background: background || '#2934D0',
          color: '#ffffff'
        }
      case 'delete':
        return {}
      default:
        return {}
    }
  }

  return (
    <Wrap
      fullWidth={fullWidth}
      background={background}
      onClick={onClick}
      width={width}
      height={height}
      disabled={disabled}
      fontSize={fontSize}
      type={type}
      style={{...getTheme(), ...style}}
    >
      {loading ? <Loader/> : title}
    </Wrap>
  )
}

Button.propTypes = {
  title: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
  width: PropTypes.string,
  fontSize: PropTypes.string,
  background: PropTypes.string,
  theme: PropTypes.string,
  height: PropTypes.string,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool
}

Button.defaultProps = {
  theme: 'primary',
  width: '100%'
}
export default Button
