import { equals } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import toSnakeCase from "../helpers/toSnakeCase";
import {openErrorAction} from "../actions/error";
import {mapResponseToFormError} from "../helpers/form";
import {getDataFromState} from "../helpers/get";

const useCreate = params => {
  const {
    action,
    stateName,
    reject,
    withError,
    withMessage,
    errorModalParams = {},
    onlyNetworkError,
    redirectUrl,
    onSuccess,
    serializer = toSnakeCase
  } = params

  const dispatch = useDispatch()
  const history = useHistory()

  const data = useSelector(state => getDataFromState(stateName, null, state), equals)
  const onSubmit = values => {
    return dispatch(action(serializer(values)))
      .then(data => {
        if (onSuccess) {
          onSuccess(data, { values })
          return data
        } else if (redirectUrl) {
          history.push(redirectUrl)
          return data
        }
        return data
      })
      .catch(e => {
        if (reject) {
          return Promise.reject(e)
        } else if (withError) {
          if (onlyNetworkError) {
            return Promise.reject(e)
          } else {
            dispatch(openErrorAction({ errMessage: e, ...errorModalParams }))
            return Promise.reject(e)
          }
        }
        return mapResponseToFormError(e, dispatch)
      })
  }

  return { onSubmit, ...data }
}

export default useCreate
