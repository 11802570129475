import styled from 'styled-components'

export const BasicBar = styled('nav')`
  ${({theme}) => theme.nav.primary};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 110
`
