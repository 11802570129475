import axios from 'axios'
import sprintf from 'sprintf'
import responseToCamelCase from 'helpers/responseToCamelCase';
import storage from './storage';

const axiosRequest = () => {
  const server = storage('server').get()
  const url = '%s/ru/api/v1/'
  axios.defaults.baseURL = sprintf(url, server)
  axios.defaults.transformResponse = [responseToCamelCase]
  // axios.defaults.headers.common.Authorization = `Token ${token}`
  return axios
}

export default axiosRequest
