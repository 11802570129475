import axios from 'helpers/axios';
import * as API from 'constants/api'
import * as ACTION_TYPES from 'redux/types'
import {path, prop} from 'ramda';
import mapIndexed from 'helpers/mapIndexed';
import toSnakeCase from "../helpers/toSnakeCase";

export const orderCreateAction = ({products, ...data}) => {
  const telegram_auth = window.Telegram.WebApp.initData


  const serializedData = {
    ...data,
    'telegram_id': path(['Telegram', 'WebApp', 'initDataUnsafe', 'user', 'id'], window),
    'products': products && mapIndexed((item) => {
      const amount = prop('amount', item)
      const id = prop('id', item)
      const cashPrice = prop('cashPrice', item)
      return {
        'product': Number(id),
        'price': Number(cashPrice),
        'amount': Number(amount)
      }
    }, products),
    telegram_auth,
  }
  const payload = axios()
    .post(API.CREATE_ORDER, {...toSnakeCase(serializedData)})
    .then((response) => {
      localStorage.clear()
      window.Telegram.WebApp.close()
      return path(['data'], response)
    })
    .catch((error) => {
      return Promise.reject(path(['response', 'data'], error))
    })

  return {
    type: ACTION_TYPES.ORDER_CREATE_ACTION,
    payload
  }
}
