import axios from "../helpers/axios";
import * as API from "../constants/api";
import {path} from "ramda";
import * as ACTION_TYPES from "../redux/types";
import {CREATE_ADDRESS, GET_ADDRESS} from "../constants/api";

export const addressListFetchAction = () => {
  const telegram_auth = window.Telegram.WebApp.initData
  const requestData = {
    telegram_auth,
  }
  const payload = axios()
      .post(API.GET_ADDRESS, requestData)
      .then((response) => {
        return path(['data'], response)
      })
      .catch((error) => {
        return Promise.reject(path(['response', 'data'], error))
      })

  return {
    type: ACTION_TYPES.GET_ADDRESS,
    payload
  }
}

export const addressCreateAction = (data) => {
  const telegram_auth = window.Telegram.WebApp.initData
  const requestData = {
    telegram_auth,
    ...data
  }
  const payload = axios()
      .post(API.CREATE_ADDRESS, requestData)
      .then((response) => {
        return path(['data'], response)
      })
      .catch((error) => {
        return Promise.reject(path(['response', 'data'], error))
      })

  return {
    type: ACTION_TYPES.CREATE_ADDRESS,
    payload
  }
}

