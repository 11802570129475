import storage from 'helpers/storage';
import {filter, isEmpty, map, path, prop, sum} from 'ramda';
import {useEffect, useState} from 'react';

const BASKET = 'BASKET'

const useCard = (product) => {
  const [present, setPresent] = useState(false)

  const [total, setTotal] = useState(0)

  const [products, setProducts] = useState([])

  const [currentProduct, setCurrentProduct] = useState({})

  const basketChanged = new CustomEvent('basketChanged')

  const basket = storage(BASKET).get() || []

  const id = Number(prop('id', product))

  const isExist = (get = false) => {
    const filtered = basket ? filter((item) => {
      return id === Number(prop('id', item))
    }, basket) : []
    return get ? filtered[0] : !isEmpty(filtered)
  }

  const setBasket = (amount) => {
    return basket && map((item) => {
      if (id === Number(prop('id', item))) {
        const currentAmount = Number(prop('amount', item))
        return {
          ...item,
          amount: Number(amount) + currentAmount
        }
      }
      return item
    }, basket)
  }

  const changeBasket = (amount) => {
    return basket && map((item) => {
      if (Number(id) === Number(prop('id', item))) {
        return {
          ...item,
          'amount': Number(amount) > 0 ? Number(amount) : 0
        }
      }
      return item
    }, basket)
  }

  const removeBasket = () => {
    return basket && filter((item) => {
      return Number(prop('id', item)) !== id
    }, basket)
  }

  const handleAdd = (amount = 1, handleOpen) => {
    const checkExist = isExist()
    if (!checkExist) {
      handleOpen()
    } else {
      storage(BASKET).set(setBasket(amount))
    }
    dispatchEvent(basketChanged)
  }

  const handleChange = (value) => {
    const checkExist = isExist()
    if (checkExist) {
      const added = changeBasket(value)
      storage(BASKET).set(added)
    } else {
      const added = [...basket, {...product, amount: value}]
      storage(BASKET).set(added)
    }
    dispatchEvent(basketChanged)
    window.productAmount = null
    window.productBox = null
  }

  const handleRemove = (amount = -1) => {
    const checkExist = isExist(true)
    const currentAmount = prop('amount', checkExist)
    if (currentAmount > 1) {
      storage(BASKET).set(setBasket(amount))
    } else {
      storage(BASKET).set(removeBasket())
    }
    dispatchEvent(basketChanged)
  }

  const getTotal = (products) => {
    const mapped = products && map((item) => {
      const amount = Number(prop('amount', item))
      const cashPrice = Number(prop('cashPrice', item))
      return amount * cashPrice
    }, products)
    return mapped ? sum(mapped) : 0
  }

  const filterProducts = (products) => {
    return products ? filter((item) => {
      return Number(prop('amount', item)) !== 0
    }, products) : []
  }

  useEffect(() => {
    const products = storage(BASKET).get()
    setPresent(isExist())
    setTotal(getTotal(products))
    setProducts(filterProducts(products))
    storage(BASKET).set(filterProducts(products))
    setCurrentProduct(isExist(true))
  }, [])

  window.addEventListener('basketChanged', () => {
    const checkBasket = storage(BASKET).get() || []
    const filtered = filter((item) => {
      return id === Number(prop('id', item))
    }, checkBasket)
    setProducts(filterProducts(checkBasket))
    setPresent(!isEmpty(filtered))
    setTotal(getTotal(checkBasket))
    storage(BASKET).set(filterProducts(checkBasket))
    setCurrentProduct(filtered[0])
  })

  const amount = path(['amount'], currentProduct)

  return {
    'handleAdd': handleAdd,
    'total': total,
    'products': products,
    'handleChange': handleChange,
    'handleRemove': handleRemove,
    'exist': present && Number(prop('amount', currentProduct)) !== 0 && Number(prop('amount', currentProduct)) > 0,
    'amount': amount ? Number(amount) : 0
  }
}

export default useCard
