import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {BasicBar} from 'components/Navigation/TopBar/BasicBar';
import {ArrowLeft} from 'react-feather';

const Wrap = styled(BasicBar)`
  height: 55px;
  padding: 15px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background: ${({background}) => background || 'white'};
  border-bottom: ${({border}) => border || '1px solid #EFEFEF'};
`

const MiddleBar = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 17px !important;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #282828;
`

const Bar = styled('div')`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  margin-right: 5px;
  cursor: pointer;
  height: 100%;
  min-width: 74px;
  max-width: 74px;
  justify-content: center;
  align-items: center;
  color: ${({theme}) => theme.palette.black};

  svg:first-child {
    color: ${({theme}) => theme.palette.black};
  }
`

const TitleBar = props => {
  const {
    title,
    background,
    border,
    icon,
    onClick
  } = props
  const getIcon = () => {
    switch (icon) {
      case 'back':
        return <ArrowLeft size={24}/>
      default:
        return ''
    }
  }
  const selectedIcon = getIcon()
  return (
    <Wrap
      background={background}
      border={border}
    >
      {
        selectedIcon ?
          <Bar onClick={onClick}>
            {selectedIcon}
          </Bar>
          : null
      }
      <MiddleBar>
        {title}
      </MiddleBar>
    </Wrap>
  )
}

TitleBar.propTypes = {
  title: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.oneOf(['back']).isRequired
}

export default TitleBar
