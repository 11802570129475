import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Icon = styled('svg')`
  transition: all .3s;
  min-width: 1em;
`

const SvgIcon = ({classes, className, children, ...props}) => {
  return (
    <Icon
      fill={'currentColor'}
      {...props}
    >
      {children}
    </Icon>
  )
}

SvgIcon.propTypes = {
  color:PropTypes.string,
  className:PropTypes.string,
  fontSize:PropTypes.string,
  viewBox:PropTypes.string
}

SvgIcon.defaultProps = {
  fontSize:'24px',
  viewBox:'0 0 24 24',
  height:'1em',
  width:'1em',
  xmlns:'http://www.w3.org/2000/svg'
}

export default SvgIcon
