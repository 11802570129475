import React from 'react';
import VerificationCodeField from "./VerificationCodeField";
import {Field} from "react-final-form";
import Tag from "../../../Tag";
import {Edit} from "react-feather";
import styled from "styled-components";
import Timer from "./Timer";

const FieldWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 10px;
`
export const vertificationFields =[
  'phone',
  'code'
]
const VerificationCode = ({values, sendConfirm, setValue}) => {
  const phone = '+998' + (values?.phone || '')

  return (
      <div>
        <p>
          Введите код подтверждения из SMS.
          Код отправлен на Ваш номер
        </p>
        <FieldWrapper>
          <Tag text={phone} icon={<Edit size={16}/>} onClick={() => setValue(0)}/>
        </FieldWrapper>
        <FieldWrapper>
          <Field
              name={'code'}
              component={VerificationCodeField}
          />
        </FieldWrapper>
        <Timer time={60} onAfterClick={() => sendConfirm.onSubmit({ phone: values?.phone })} />
      </div>
  );
};

export default VerificationCode;
