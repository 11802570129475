import {replace, length, startsWith} from 'ramda'

export function withoutSpaceParse(value) {
  if (!value) return ''
  return replace(/ /g, '', value)
}

export function phoneNumberParse(value) {
  if (!value) return ''

  const withoutSpaces = withoutSpaceParse(value)
  const onlyNumsAndSymbol = replace(/[^\d\W]/g, '', withoutSpaces)

  const operatorCode = onlyNumsAndSymbol.slice(0, 2)
  if (length(onlyNumsAndSymbol) <= 2) {
    return `${operatorCode}`
  } else if (length(onlyNumsAndSymbol) <= 5) {
    return `${operatorCode} ${onlyNumsAndSymbol.slice(2, 5)}`
  } else if (length(onlyNumsAndSymbol) <= 7) {
    return `${operatorCode} ${onlyNumsAndSymbol.slice(2, 5)} ${onlyNumsAndSymbol.slice(5, 7)}`
  } else if (length(onlyNumsAndSymbol) <= 9 || length(onlyNumsAndSymbol) > 9) {
    return `${operatorCode} ${onlyNumsAndSymbol.slice(2, 5)} ${onlyNumsAndSymbol.slice(
        5,
        7
    )} ${onlyNumsAndSymbol.slice(7, 9)}`
  }
}

export function cleanPhoneNumber(phoneNumber) {
  return phoneNumber.replace(/\D/g, '')
}

