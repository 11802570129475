import React from 'react';
import 'styles/index.css';
import {ThemeProvider} from 'styled-components';
import {theme} from './constants/theme';
import RouterProvider from 'providers/router';
import {prop} from 'ramda'
import storage from 'helpers/storage';
import {parseParams} from 'helpers/url';

function App() {
  console.error = () => {}
  const hash = window.location.hash
  const search = hash.split('?')[1]
  const queryParams = parseParams(`?${search}`)
  const server = prop('server', queryParams)
  const language = prop('language', queryParams) || 'ru'

  if (server) {
    storage('server').set(server)
  }
  if (language) {
    storage('language').set(language)
  }
  return (
    <ThemeProvider theme={theme}>
        <RouterProvider />
    </ThemeProvider>
  );
}

export default App;
