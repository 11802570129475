import React, {useEffect, useState} from 'react';
import TitleBar from 'components/Navigation/TitleBar';
import ListCard from 'components/ListCard';
import Container from 'components/Container';
import Mapper from 'components/Mapper';
import {useHistory} from 'react-router';
import {ROOT_PATH} from 'constants/routes';
import styled from 'styled-components';
import formatNumber from 'helpers/formatNumber';
import {ChevronRight} from 'react-feather';
import Description from 'components/Description';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import {useTranslate} from 'helpers/translate';
import {path} from "ramda";
import ModalCheckout from "../../../ModalCheckout";
import AddAddressModal from "../../../ModalCheckout/AddAddress/AddAddressModal";

const Total = styled('div')`
  margin-top: 10px;
  background: white;
  padding: 15px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 5%);
  border-radius: 10px;
`

const Fixed = styled('div')`
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 55px;
  background: #4c956c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  color: white;
`

const Basket = styled('div')`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    margin-top: 3px;
    margin-left: 3px;
  }
`

const styles = {
  wrapper: {
    marginTop: '5px'
  },
  button: {
    marginTop: '15px'
  },
  parent: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    color: "#333333"
  },
  child: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    color: '#4c956c'
  }
}

const BasketGrid = (props) => {
  const {products, total, loading} = props
  const [open, setOpen] = useState(false)
  const [openAddress, setOpenAddress] = useState(false)
  const [loading1, setLoading] = useState(false)
  useEffect(() => {
    if (loading1) {
      setTimeout(() => setLoading(false), 300)
    }
  }, [loading1])
  const history = useHistory()
  const {t} = useTranslate()
  const currency = path(['0', 'currency', 'sign'], products)
  return (
    <>
      <TitleBar
        title={t('basket')}
        icon={'back'}
        onClick={() => {
          history.push({
            pathname: ROOT_PATH
          })
        }}
      />
      <Container hasStatusBar hasFixedBar>
        <Total>
          <Description
            withOutMargin
            parent={t('order_price')}
            child={formatNumber(total, currency, ' ')}
            parentStyles={styles.parent}
            childStyles={styles.child}
          />
        </Total>
        <Mapper
          loading={loading1}
          data={products}
          itemProps={{
            onDelete: () => setLoading(true)
          }}
          item={ListCard}
        />
        {open && <ModalCheckout
            products={products}
            setOpenAddress={setOpenAddress}
            open={open}
            onClose={() => setOpen(!open)}
        />}
        <AddAddressModal open={openAddress} onClose={() => setOpenAddress(false)}/>
        <Fixed onClick={() => {
          !loading && setOpen(!open)
        }}>
          {
            loading ?
              <Loader/>
              :
              <>
                <p>{formatNumber(total, currency, ' ')}</p>
                <Basket>
                  <p>{t('go_to_checkout')}</p>
                  <ChevronRight size={20}/>
                </Basket>
              </>
          }

        </Fixed>
      </Container>
    </>
  )
}

BasketGrid.propTypes = {
  total: PropTypes.any.isRequired,
  products: PropTypes.any.isRequired
}

export default BasketGrid
