const storage = key => ({
  set:value => {
    localStorage.setItem(key, JSON.stringify(value))
  },
  get:() => {
    const value = localStorage.getItem(key)
    const isDefined = value !== undefined && value !== 'undefined' && value
    return isDefined ? JSON.parse(value) : null
  }
})

export default storage
