import React from 'react'
import PropTypes from 'prop-types'

import AddressesField from "../../ModalCheckout/AddAddress/AddressesField";
import Button from "../../Navigation/Components/Button";
import {isEmpty} from "ramda";
import NoResults from "../../NoData/NoResults";
import {useTranslate} from "../../../helpers/translate";



const YandexMapField = ({setOpenAddress, addressList}) => {
  const {t} = useTranslate()

  const addresses = addressList?.data || []
  const onOpenToggle = () => setOpenAddress(true)
  return (
    <>
      <div>
        {isEmpty(addresses) && !addressList.loading ? (
            <NoResults text={t('address_not_found')} height={'min-content'}/>
        ) : (
            <AddressesField
                title={t('address_delivery')}
                name={'userAddress'}
                options={addresses.map(item => {
                  const addressParts = [
                    item.building ? `${t('house')} ${item.building}` : null,
                    item.entrance ? `${t('entrance')} ${item.entrance}` : null,
                    item.floor ? `${t('floor')} ${item.floor}` : null,
                    item.room ? `${t('apartment')} ${item.room}` : null
                  ];
                  const filteredAddressParts = addressParts.filter(Boolean);
                  return {
                    text: item.name,
                    subText: filteredAddressParts.join(", "),
                    id: item.id.toString()
                  }
                })}
            />
        )}
        <Button
            title={isEmpty(addresses) ? t('add_address') : t('order_another_address')}
            onClick={onOpenToggle}
            width={'150px'}
            type={'button'}
            loading={addressList.loading}
            disabled={addressList.loading} fullWidth={true}
        />
      </div>
    </>
  )
}

YandexMapField.propTypes = {
  fields: PropTypes.object
}

export default YandexMapField
