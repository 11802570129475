import React, {Fragment} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import createStore from 'redux/store';
import {Provider} from 'react-redux';

const store = createStore()

const root = ReactDOM.createRoot(document.getElementById('wrapper'));

root.render(
  <Provider store={store}>
    <Fragment>
        <App/>
    </Fragment>
  </Provider>
);
