import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ClipboardIcon from 'components/SvgIcons/CustomIcons/Clipboard'
import {appendParamsToUrl, parseParams} from 'helpers/url'
import {path, propOr} from 'ramda'
import {useHistory} from 'react-router'

const Wrap = styled('div')`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: ${({height}) => height || 'calc(100vh - 220px)'};
  padding: 25px 10px;
  margin: 0 auto;
  ${({styles}) => styles};
`
const Text = styled('p')`
  max-width: 170px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0241176em;
  color: ${({theme}) => theme.palette.grey};
`
const Img = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: rgba(140, 140, 140, 0.1);
  border-radius: 8px;
  min-width: 44px;
  min-height: 44px;
  margin-bottom: 10px;
  padding: 14px;
`
const Button = styled('button')`
  color: ${({theme}) => theme.palette.blue};
  font-weight: 500;
  font-size: 17px;
  background: transparent;
  border: none;
  width: auto;
  margin-top: 10px;
  outline: none !important;
  &:disabled{
    color: inherit;
  }
`

const NoResults = props => {
  const {
    text = 'no_data',
    height,
    styles,
    withRefresh,
    refreshText = 'Обновить',
    refreshQuery = 'refresh',
    refreshDisabled,
    refreshFunc
  } = props

  // Location
  const history = useHistory()
  const search = path(['location', 'search'], history)
  const searchObj = parseParams(search)
  const refreshValue = propOr(0, refreshQuery, searchObj)

  // Handlers
  const handleRefresh = () => {
    history.push({
      search:appendParamsToUrl({[refreshQuery]:(+refreshValue + 1)}, search)
    })
  }

  // Render
  return (
    <Wrap
      height={height}
      styles={styles}
    >
      <Img>
        <ClipboardIcon
          color={'#8c8c8c'}
        />
      </Img>
      <Text>{text}</Text>
      {withRefresh && (
        <Button
          type={'button'}
          onClick={refreshFunc || handleRefresh}
          disabled={refreshDisabled}
        >
          {refreshText}
        </Button>
      )}
    </Wrap>
  )
}

NoResults.propTypes = {
  text:PropTypes.any,
  withRefresh:PropTypes.bool,
  refreshDisabled:PropTypes.bool,
  styles:PropTypes.object,
  refreshText:PropTypes.string,
  refreshQuery:PropTypes.string,
  refreshFunc:PropTypes.func,
  height:PropTypes.string
}

export default NoResults
