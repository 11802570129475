import * as ACTION_TYPES from 'redux/types'
import errorDialog from 'helpers/openErrorDialog'

export const defaultState = {
  open:false,
  message:null,
  errMessage:null
}

const errorReducer = () => {
  return errorDialog(defaultState, {
    [`${ACTION_TYPES.ERROR_OPEN}`] (state, {payload}) {
      return {
        ...state,
        ...payload,
        open:true
      }
    },

    [`${ACTION_TYPES.ERROR_CLOSE}`] (state) {
      return {
        ...state,
        open:false
      }
    }
  })
}

export default errorReducer
