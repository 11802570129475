import styled from 'styled-components';
import PropTypes from 'prop-types';
import ModalError from 'components/ModalError';

const Background = styled('div')`
  position: relative;
  background: #F5F5F5;
  min-height: 100vh;
  width: 100%;
`

const Wrapper = styled('div')`
  width: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
`

const Layout = (props) => {
  const {children} = props
  return (
    <Wrapper>
      <ModalError />
      <Background>
        {children}
      </Background>
    </Wrapper>
  )
}


Layout.propTypes = {
  children: PropTypes.any.isRequired
}

export default Layout
