import {combineReducers} from 'redux'
import * as STATES from './states'
import * as ACTION_TYPES from 'redux/types'
import createThunkReducer from 'helpers/createThunkReducer';
import errorReducer from './errorReducer';

const rootReducer = combineReducers({
  [STATES.ORDER_CREATE]: createThunkReducer(ACTION_TYPES.ORDER_CREATE_ACTION),
  [STATES.CHECK_AUTH]: createThunkReducer(ACTION_TYPES.CHECK_AUTH),
  [STATES.SEND_CONFIRM]: createThunkReducer(ACTION_TYPES.SEND_CONFIRM),
  [STATES.CONFIRM_PHONE]: createThunkReducer(ACTION_TYPES.CONFIRM_PHONE),
  [STATES.GET_ADDRESS]: createThunkReducer(ACTION_TYPES.GET_ADDRESS),
  [STATES.CREATE_ADDRESS]: createThunkReducer(ACTION_TYPES.CREATE_ADDRESS),
  [STATES.PRODUCTS]: combineReducers({
    [STATES.LIST]: createThunkReducer(ACTION_TYPES.MAIN_PRODUCTS_LIST_FETCH_ACTION),
    [STATES.ITEM]: createThunkReducer(ACTION_TYPES.MAIN_PRODUCTS_ITEM_FETCH_ACTION)
  }),
  [STATES.BRANDS]: combineReducers({
    [STATES.LIST]: createThunkReducer(ACTION_TYPES.BRANDS_LIST_FETCH_ACTION)
  }),
  [STATES.HISTORY]: combineReducers({
    [STATES.LIST]: createThunkReducer(ACTION_TYPES.HISTORY_LIST_FETCH_ACTION),
    [STATES.ITEM]: createThunkReducer(ACTION_TYPES.HISTORY_ITEM_FETCH_ACTION)
  }),
  [STATES.ERROR]: errorReducer()
})

export default rootReducer
