import React, {useEffect, useState} from 'react';
import Container from 'components/Container';
import TextField from 'components/Fields/Text';
import {ChevronRight, Grid, List, Menu, Search} from 'react-feather';
import MainBar from 'components/Navigation/MainBar';
import Card from 'components/Card';
import {Col, Row} from 'react-flexbox-grid';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import * as PATH from 'constants/routes';
import PropTypes from 'prop-types';
import Mapper from 'components/Mapper';
import ListCard from 'components/ListCard';
import useCard from 'hooks/useCard';
import formatNumber from 'helpers/formatNumber';
import storage from 'helpers/storage';
import {useTranslate} from 'helpers/translate';
import {isEmpty, path} from 'ramda';
import FiltersBar from 'components/Navigation/Components/FiltersBar';
import MainFilter from './MainFilter';
import Loader from 'components/Loader';
import Button from 'components/Navigation/Components/Button';
import {useDispatch} from 'react-redux';
import * as ACTION_TYPES from 'redux/types';

const VIEW_TYPE = 'VIEW_TYPE'

const Fixed = styled('div')`
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 55px;
  background: linear-gradient(180deg, #4c956c 0%, #4c956c 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  color: white;
`

const Basket = styled('div')`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & svg {
    margin-top: 3px;
    margin-left: 3px;
  }
`

const MainGrid = (props) => {
  const {
    list,
    brands,
    moreLoading,
    setBrands,
    productTypes,
    setProductTypes,
    setSubmit,
    price,
    loading,
    setPrice,
    hasMore,
    setPage,
    page
  } = props

  const {total} = useCard(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const {t} = useTranslate()

  const [focused, setFocused] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)
  const [viewType, setViewType] = useState(storage(VIEW_TYPE).get() || 'card')

  useEffect(() => {
    storage(VIEW_TYPE).set(viewType)
  }, [viewType])

  const currency = path(['0', 'currency', 'sign'], list)

  const styles = {
    'col': {
      'padding': '5px 0 0 0'
    },
  }

  // const filter = (
  //   <FiltersBar
  //     onClear={() => {
  //       setPrice({from: null, to: null})
  //       setBrands([])
  //       setProductTypes([])
  //       setFilterOpen(false)
  //       setSubmit(Math.random())
  //       storage('brands').set([])
  //       storage('productTypes').set([])
  //       storage('price').set({from: null, to: null})
  //     }}
  //     setOpen={setFilterOpen}
  //     isOpen={filterOpen}
  //     withOutButton
  //   >
  //     <MainFilter
  //       price={price}
  //       setPrice={setPrice}
  //       setSubmit={setSubmit}
  //       setFilterOpen={setFilterOpen}
  //       productTypes={productTypes}
  //       setProductTypes={setProductTypes}
  //       setBrands={setBrands}
  //       brands={brands}
  //     />
  //   </FiltersBar>
  // )

  const firstIcon = {
    'icon': <Menu stroke={'#282828'}/>,
    'onClick': () => history.push(PATH.PROFILE_PATH)
  }

  const lastBtn = {
    'icon': viewType === 'list' ? <Grid stroke={'#282828'}/> : <List stroke={'#282828'}/>,
    'onClick': () => setViewType(viewType === 'grid' ? 'list' : 'grid')
  }

  return (
    <>
      <MainBar
        firstBtn={firstIcon}
        lastBtn={lastBtn}
        title={(
          <>
            <TextField
              onChange={() => {
                dispatch({
                  type: ACTION_TYPES.MAIN_PRODUCTS_LIST_FETCH_ACTION,
                  payload: []
                })
              }}
              focused={focused}
              setFocused={setFocused}
              placeholder={t('search_products', '. . .')}
              searchKey={'search'}
              searchable
              prefix={<Search/>}
              fullWidth
            />
          </>
        )}
      />
      <Container
        hasStatusBar
        hasFixedBar={!focused && total !== 0 && total > 0}
      >
        <Mapper
          data={list}
          item={viewType === 'list' ? ListCard : Card}
          loading={loading}
          listElement={viewType === 'list' ? null : Row}
          itemElementProps={{
            xs: 6,
            style: viewType === 'list' ? {} : styles.col
          }}
          itemProps={{
            withAvailable: true,
            width: '100%'
          }}
          itemElement={viewType === 'list' ? null : Col}
        />
        {
          loading || isEmpty(list) ?
            null
            : moreLoading
              ? <Loader/>
              : hasMore ? <Button
                background={'rgb(41, 52, 208)'}
                height={'45px'}
                style={{
                  margin: '10px 0'
                }}
                title={'Показать больше'}
                onClick={() => hasMore ? setPage(page + 1) : ''}
              /> : null
        }
        {
          !focused && total !== 0 && total > 0 ?
            <Fixed
              onClick={() => {
                history.push({
                  pathname: PATH.BASKET_PATH
                })
              }}
            >
              <p>{formatNumber(total, currency, ' ')}  </p>
              <Basket>
                <p>{t('basket')}</p>
                <ChevronRight size={20}/>
              </Basket>
            </Fixed>
            : null
        }
      </Container>
      {/*{filter}*/}
    </>
  )
}

MainGrid.propTypes = {
  list: PropTypes.array
}

export default MainGrid
