import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {useTranslate} from 'helpers/translate';
import {Minus, Plus, Trash2} from "react-feather";
import Button from "../Navigation/Components/Button";
import {bottomSheet} from "react-simple-bottom-sheet";

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
`;

const Label = styled('label')`
  flex-grow: 1;
  height: max-content;
  position: relative;
`

const Circle = styled('div')`
  background: #2934D0;
  border-radius: 100%;
  height: 35px;
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Input = styled('input')`
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
  border: 1px solid #DFE0E3;
  border-radius: 5px;
  height: 45px;
  padding-left: 12px;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 145%;
    color: #69707E;
    mix-blend-mode: normal;
  }
`;

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  background-color: #f5f5ff;
  border-radius: 20px;
`;

const CountDisplay = styled.input`
  width: 30px;
  text-align: center;
  border: none;
  background: transparent;
  font-size: 20px;
  color: #6b00ff;
  &:focus {
    outline: none;
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;


const SetAmount = (props) => {
  const {amount, name = 'productAmount', isOrder} = props
  const ref = useRef(null);
  const {t} = useTranslate()
  if (!isOrder) {
    const handleIncrement = () => {
      const currentValue = parseInt(ref.current.value) || 0;
      const newValue = currentValue + 1;
      ref.current.value = newValue
      window[name] = Number(newValue)
    };

    const handleDecrement = () => {
      const currentValue = parseInt(ref.current.value) || 0;  // Получаем текущее значение, если пусто — ставим 0
      const newValue = (currentValue - 1) < 0 ? 0 : currentValue - 1
      ref.current.value = newValue
      window[name] = Number(newValue)
    };

    const handleChange = (e) => {
    };
    return (
      <Wrapper>
        <CounterContainer>
          <IconButton onClick={handleDecrement}>
            <Minus size={20} />
          </IconButton>
          <CountDisplay type="number" onChange={handleChange} defaultValue={amount || 0} ref={ref} readOnly />
          <IconButton onClick={handleIncrement}>
            <Plus size={20} />
          </IconButton>
        </CounterContainer>
      </Wrapper>
    );
  }
  return (
    <>

    </>
  )
};

SetAmount.propTypes = {
  amount: PropTypes.any
}

export default SetAmount;
