import React, {useState} from 'react';
import HistoryGrid from './Grid';
import useFetch from 'hooks/useFetch';
import * as STATES from 'redux/reducers/states';
import {historyListFetchAction} from 'actions/history';
import {isEmpty, path, prop} from 'ramda';
import {useHistory} from "react-router";
import {parseParams} from "../../../helpers/url";

const HistoryContainer = () => {
  const [page, setPage] = useState(1)
  const history = useHistory()
  const location = prop('location', history)
  const search = prop('search', location)
  const searchObj = parseParams(search)
  const status = prop('status', searchObj)

  const historyList = useFetch({
    'action': () => historyListFetchAction(page, page !== 1, status),
    'stateName': STATES.HISTORY,
    'dependencies': [page],
    'child': 'list'
  })

  const hasMore = path(['data', 'next'], historyList)
  const list = path(['data', 'results'], historyList)
  const loading = page !== 1 ? false : path(['loading'], historyList) && !isEmpty(list)
  const moreLoading = page === 1 ? false : path(['loading'], historyList)

  return (
    <HistoryGrid
        status={status}
      setPage={setPage}
      page={page}
      hasMore={hasMore}
      moreLoading={moreLoading}
      loading={loading}
      historyList={historyList}
    />
  );
};

export default HistoryContainer;
