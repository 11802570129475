import { omit, pick, join, isEmpty, pipe, prop } from 'ramda'
import { FORM_ERROR } from 'final-form'

// const transform = pipe(prop('1'), map(item => Object.values(item)))
export const mapResponseToFormError = (data, dispatch) => {
  const fieldErrors = omit(['nonFieldErrors'], data)

  const commonErrors = pick(['nonFieldErrors'], data || {})

  if (isEmpty(commonErrors)) {
    return fieldErrors
  } else {

  }
  return {
    ...fieldErrors,
    [FORM_ERROR]: join(', ', commonErrors.nonFieldErrors)
  }
}

const toArray = err => {
  if (!err) {
    return []
  }

  if (Array.isArray(err)) {
    return err
  }

  return [err]
}
export const getFieldError = pipe(prop('submitError'), toArray, join(','))
