import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled('div')`
  width: 100%;
  background: inherit;
  padding: ${({hasStatusBar, hasFixedBar, top, bottom}) => hasStatusBar ? hasFixedBar ? `54px 16px ${bottom || '55px'} 16px` : `${top || '54px'} 16px 6px 16px` : '0 16px 6px 16px'};
`

const Container = (props) => {
  const { children, hasStatusBar, hasFixedBar, top, bottom} = props
  return (
    <Wrapper hasStatusBar={hasStatusBar} hasFixedBar={hasFixedBar} top={top} bottom={bottom}>
      {children}
    </Wrapper>
  )
}

Container.propTypes = {
  children: PropTypes.any.isRequired,
  hasStatusBar: PropTypes.bool,
  top: PropTypes.string,
  bottom: PropTypes.string,
  hasFixedBar: PropTypes.bool
}

export default Container
