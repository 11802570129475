import axios from "../helpers/axios";
import * as API from "../constants/api";
import {path} from "ramda";
import * as ACTION_TYPES from "../redux/types";

export const userCheckCreateAction = (data) => {
  const telegram_auth = window.Telegram.WebApp.initData
  const requestData = {
    telegram_auth
  }
  const payload = axios()
      .post(API.CHECK_AUTH, requestData)
      .then((response) => {
        return path(['data'], response)
      })
      .catch((error) => {
        return Promise.reject(path(['response', 'data'], error))
      })

  return {
    type: ACTION_TYPES.CHECK_AUTH,
    payload
  }
}

export const sendConfirmCreateAction = (data) => {
  const telegram_auth = window.Telegram.WebApp.initData
  const requestData = {
    telegram_auth,
    ...data
  }
  const payload = axios()
      .post(API.SEND_CONFIRM, requestData)
      .then((response) => {
        return path(['data'], response)
      })
      .catch((error) => {
        return Promise.reject(path(['response', 'data'], error))
      })

  return {
    type: ACTION_TYPES.SEND_CONFIRM,
    payload
  }
}

export const confirmPhoneCreateAction = (data) => {
  const telegram_auth = window.Telegram.WebApp.initData
  const requestData = {
    telegram_auth,
    ...data
  }
  const payload = axios()
      .post(API.CONFIRM_PHONE, requestData)
      .then((response) => {
        return path(['data'], response)
      })
      .catch((error) => {
        return Promise.reject(path(['response', 'data'], error))
      })

  return {
    type: ACTION_TYPES.CONFIRM_PHONE,
    payload
  }
}
